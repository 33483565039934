

const style = (theme) => ({
    muiIconButtonRoot: {
        [theme.breakpoints.down('sm')]: {
            padding: 5
        }
    },

    muiSvgIconFontSizeLarge: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem'
        }
    },

    muiSvgIconFontSizeSmall: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    }

})

export default style;