import * as actionTypes from './actionTypes';
import axiosConfig from "util/axios";
import axios from 'axios';


// --------------VerifyUser-----------------------------
export const setUserverificationStatus = (verification_status) => {
    return {
        type: actionTypes.SET_USER_verification_STATUS,
        status: verification_status
    }
}

export const verifyUser = (Username) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/ResetPassword/VerifyUser', {
            params: {
                Username: Username,
            }
        }).then(response => {
            dispatch(setUserverificationStatus(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
        });
    }
}


// Validate OTP

export const setOTPverificationStatus = (verification_status) => {
    return {
        type: actionTypes.SET_OTP_verification_STATUS,
        OTP_STATUS: verification_status
    }
}


export const validateUernameOTP = (Username, verificationCode) => {
    return async (dispatch) => {
        await axiosConfig().get('/QuestUser/ResetPassword/ValiateOTP', {
            params: {
                Username: Username,
                OTP: verificationCode
            }
        }).then(response => {
            dispatch(setOTPverificationStatus(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

