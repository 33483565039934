import React, { useState } from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// import material component
import Avatar from '@material-ui/core/Avatar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// @material-ui/icons
import LockIcon from '@material-ui/icons/MobileFriendly';
//import Custom Components
import Progress from "components/CustomComponents/Progress/Progress"
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import CustomButton from "components/CustomComponents/CustomButtons/Button"
// custom style
import useStyles from "assets/jss/components/views/signupStyle";
//variables error
import { LENGTH_ERROR } from 'variables/general'
//validations
import { checkNull, checkMaxLength } from 'util/validations'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VerifyOTP = (props) => {
    const {
        title,
        username,
        resendOTP,
        onValidateOTP,
        onClose,
        loading,
        snackbarseverity,
        snackbarMessage,
        setSnackbarMessage
    } = props;

    const classes = useStyles();
    const [verificationCode, setVerificationCode] = useState("");
    const [errorverificationCode, setErrorCode] = useState("");

    const validateOTP = async () => {
        let validationObject = {};
        checkNull("verificationCode", verificationCode, "Verification Code is required", validationObject);
        checkMaxLength("verificationCode", verificationCode, 10, LENGTH_ERROR(10), validationObject);
        if (Object.keys(validationObject).length != 0) {
            setErrorCode(validationObject.errorverificationCode)
        } else {
            onValidateOTP(verificationCode)
        }
    }

    return (

        <React.Fragment>
            <Progress open={loading} />
            <Snackbar open={snackbarMessage ? true : false} autoHideDuration={6000} onClose={() => setSnackbarMessage("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setSnackbarMessage("")} severity={snackbarseverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <DialogTitle id="form-Change-Password" className={classes.dialogTitle} disableTypography={true}>
                <Avatar className={classes.avatar} >
                    <LockIcon />
                </Avatar>
                <Typography variant="h6">
                    Verification
                </Typography>

                <Typography variant="caption" style={{ maxWidth: 250,lineHeight:1.2 }}>
                    {title ? title : `You will get a otp on ${username} `}
                    <samp className={classes.hyperText}
                        onClick={() => onClose(false)} >
                        Edit
                    </samp>
                </Typography>

            </DialogTitle>

            <DialogContent>
                <TextInput
                    autoFocus={true}
                    label="verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    errorText={errorverificationCode}
                />
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <CustomButton size='md' round fullWidth color="primary" onClick={validateOTP} >
                    Verify
                </CustomButton>
                <CustomButton size='md' round outlined fullWidth color="primary" onClick={() => onClose(false)}>
                    Not now
                </CustomButton>
                <div className={classes.resendText}>
                    <Typography note="true" variant="caption" paragraph display="inline">
                        Did`t receive the verification OTP ?
                  </Typography>
                    <div className={classes.hyperText}>
                        <samp className={classes.hyperText}
                            onClick={resendOTP} >
                            Resend OTP
                       </samp>
                    </div>
                </div>

            </DialogActions>
        </React.Fragment>
    )
}

VerifyOTP.defaultProps = {
    title: "",
    snackbarseverity:'success'
}

VerifyOTP.propTypes = {
    title: PropTypes.string,
    username: PropTypes.string,
    resendOTP: PropTypes.func,
    onValidateOTP: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    snackbarMessage: PropTypes.string,
    snackbarseverity:PropTypes.string,
    setSnackbarMessage: PropTypes.func,
}

export default VerifyOTP;