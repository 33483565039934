import React, { useState, useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// import axios
import axiosConfig from "util/axios";
//validations
import { checkNull, checkEmail, checkMaxLength, checkMinLength } from 'util/validations'
//Common functions
import { clevertapEvents } from "util/commonfunctions";
//import Custom Components
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import CardHeader from "components/CustomComponents/Card/CardHeader.js";
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icon
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// custom style
import useStyles from "assets/jss/components/views/signupStyle";
//variables error
import { LENGTH_ERROR, MIN_LENGTH_ERROR } from 'variables/general'

const LoginDetails = (props) => {
  const { isLogin } = props;

  const dispatch = useDispatch()
  const classes = useStyles();

  const userDetails = useSelector(state => state.newuser.registerUser)

  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [state, setState] = useState({
    username: userDetails.contactNo,
    password: "",
    pincode: "",
    cityName: "",
    firstName: "",
    email: "",

    errorpincode: "",
    errorcityName: "",
    errorusername: "",
    errorpassword: "",
    errorfirstName: "",
    erroremail: "",
  })

  const [showPassword, setShowPassword] = React.useState(false);

  const onNext = async () => {
    let validationObject = {};
    checkNull("username", state.username, "Username is required", validationObject);
    checkNull("password", state.password, "Password is required", validationObject);
    checkNull("firstName", state.firstName, "Name is required", validationObject);
    checkEmail("email", state.email, "Email ID is not valid", validationObject);

    checkMaxLength("firstName", state.firstName, 50, LENGTH_ERROR(50), validationObject);
    checkMaxLength("username", state.username, 50, LENGTH_ERROR(50), validationObject);
    checkMaxLength("password", state.password, 50, LENGTH_ERROR(50), validationObject);
    checkMaxLength("pincode", state.pincode, 6, LENGTH_ERROR(6), validationObject);
    checkMaxLength("cityName", state.cityName, 50, LENGTH_ERROR(50), validationObject);
    checkMaxLength("email", state.email, 50, LENGTH_ERROR(50), validationObject);

    checkMinLength("password", state.password, 3, MIN_LENGTH_ERROR("Password", 3), validationObject);

    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorusername: "",
        errorpassword: "",
        errorpincode: "",
        errorcityName: "",
        errorlastName: "",
        erroremail: "",
        ...validationObject
      });
    } else {
      await dispatch(questActions.setUsernamePassword(state.username, state.password));
      await dispatch(questActions.setSchoolCity(state.pincode, state.cityName));
      await dispatch(questActions.setFLName(state.firstName, ""));
      await dispatch(questActions.setUserEmail(state.email))
      handleRegisterUser();
    }
  }

  // Register User
  const handleRegisterUser = () => {
    const userInfo = {
      'username': state.username,
      'contactNo': userDetails.contactNo,
      'firstName': state.firstName,
      'password': state.password,
      'pincode': state.pincode,
      'cityName': state.cityName,
      'emailId': state.email
    }
    props.handleLoading(true);

    //clevertap
    const eventData = JSON.stringify({
      'Mobilenumber': "+91"+userDetails.contactNo,
      'EmailId': state.email,
      'UserName': state.username
    });

    clevertapEvents('Registration', eventData);

    axiosConfig().post('/QuestUser/RegisterUser', userInfo).then(async response => {
      if (response.data.error) {
        props.handleLoading(false);
        setAlertType("danger")
        setAlertMessage(response.data.error)
      } else {
        if (isLogin) {
          await dispatch(questActions.authenticateUser(state.username, state.password)).catch(error => {
            props.handleLoading(false);
            setAlertType("error")
            setAlertMessage(error.message)
          });
          setAlertType("success")
          setAlertMessage("Registration completed successfully.Your Mobile Number is Your User Name")
          props.handleLoading(false);
          //props.handleNext()
        }
      }
    }).catch(function (error) {
      console.log(error);
    });
  }



  // Close Alert
  const onCloseAlert = () => {
    switch (alertType) {
      case "danger":
        setAlertMessage("")
        break;
      case "success":
        setAlertMessage("")
        props.handleNext()
        break;
      default:
        setAlertMessage("")
    }
  }


  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <GridContainer className={classes.stepContent}>
      <AlertDialog
        maxWidth="xs"
        open={alertMessage ? true : false}
        type={alertType}
        ContentText={alertMessage}
        ContentTextStyle={{ color: 'black', marginBottom: 0 }}
        title={null}
        titleStyle={{ paddingBottom: 0 }}
        cancelable={() => { }}
        action={[{ text: 'OK', onPress: () => onCloseAlert() }
        ]}
      />

      <GridItem xs={12} sm={12} md={12}>
        {/* <Paper > */}
        <CardHeader
          titleClassName={classes.titleHeader}
          className={classes.cardHeader}
          title={"Please enter Login Details"}
        />
        {/* <CardBody> */}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              disabled={userDetails.contactNo ? true : false}
              // autoFocus={true}
              label="Your User Name"
              value={state.username}
              errorText={state.errorusername}
              onChange={(e) => setState({ ...state, username: e.target.value })}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              autoFocus={true}
              type={showPassword ? 'text' : 'password'}
              id="PASSWORD"
              label="Set Password"
              value={state.password}
              errorText={state.errorpassword}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              id="FIRST_NAME"
              // autoFocus={true}
              label="Name"
              value={state.firstName}
              errorText={state.errorfirstName}
              onChange={(e) => setState({ ...state, firstName: e.target.value })}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              label="Email ID"
              value={state.email}
              errorText={state.erroremail}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
          </GridItem>
        </GridContainer>


        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              id="YOURCITY"
              label="Your City"
              value={state.cityName}
              errorText={state.errorcityName}
              onChange={(e) => setState({ ...state, cityName: e.target.value })}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              type="number"
              className={classes.number}
              label="Pincode"
              value={state.pincode}
              errorText={state.errorpincode}
              onChange={(e) => setState({ ...state, pincode: e.target.value })}
              onInput={(e) => {
                e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : ""
              }}
            />
          </GridItem>
        </GridContainer>

      </GridItem>

      <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
        <div className={classes.actionsContainer}>
          <Button
            size="normal"
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            className={classes.button}
          >
            Back
      </Button>

          <Button
            size="normal"
            variant="contained"
            color="primary"
            onClick={onNext}
            className={classes.button}
          >
            {props.activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </GridItem>
    </GridContainer>

  )
}

export default LoginDetails;