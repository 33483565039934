import React, { isValidElement, useState, useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions'
//validations
import { checkNull, checkMobileNumber } from 'util/validations'
// import axios
// import axiosConfig from "util/axios";

// import material component
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';

//import Custom Components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import { TextInput } from "components/CustomComponents/Inputs/Inputs";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// Views
import VerifyUsernameOTP from 'components/Views/ForgotPassword/VerifyUsernameOTP';

// custom style
import useStyles from "assets/jss/components/views/verifyUsernameStyle";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const VerifyUsername = (props) => {
  const dispatch = useDispatch()
  const verification_status = useSelector(state => state.resetpassword.userVerificationStatus)
  const classes = useStyles();
  const theme = useTheme();


  useEffect(() => {
    props.onChangeTitle("Forgot Password")
  }, [])

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [OTPDialog, setOTPDialog] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [isMobileValidate, setMobileValidation] = useState(false)

  const [state, setState] = useState({
    username: "",
    errorusername: "",
  })

  const onSendOTP = async () => {
    dispatch(questActions.setOTPverificationStatus({}))
    let validationObject = {};
    checkNull("username", state.username, "Username is required", validationObject);

    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorusername: "",
        ...validationObject
      });
    } else {
      setState({ ...state, errorusername: "" });
      props.handleLoading(true);
      dispatch(questActions.verifyUser(state.username)).then(() => {
        props.handleLoading(false);
      }).catch(error => {
        props.handleLoading(false);
        setAlertType("danger")
        setAlertMessage(error.message)
      });
    }
  }

  useEffect(() => {
    if (verification_status.isSuccess) {
      setOTPDialog(true)
    } else {
      setAlertType("info")
      setAlertMessage(verification_status.message)
    }
  }, [verification_status])



  const verifyMobile = (isVerify, message) => {
    if (isVerify) {
      props.setUsername(state.username)
      setSnackbarMessage(message)
      setTimeout(() => {
        props.handleNext()
      }, 1000)
    }
    else {
      setAlertType("danger")
      setAlertMessage(message)
    }
  }

  const onCloseAlert = () => {
    switch (alertType) {
      case "danger":
        setAlertMessage("")
        break;
      case "success":
        setAlertMessage("")
        props.handleNext()
        break;
      default:
        setAlertMessage("")
    }
  }



  return (
    <React.Fragment>
      {/*-----Dialog----- */}
      <Dialog open={OTPDialog} maxWidth={"xs"}  fullScreen={fullScreen} >
        <VerifyUsernameOTP
          title={verification_status.message}
          username={state.username}
          onClose={setOTPDialog}
          onValidate={verifyMobile}
          onResendOTP={onSendOTP}
        />
      </Dialog>

      {/* ---AlertDialog--- */}
      <AlertDialog
        maxWidth="xs"
        open={alertMessage ? true : false}
        type={alertType}
        ContentText={alertMessage}
        ContentTextStyle={{ color: 'black', marginBottom: 0 }}
        title={null}
        titleStyle={{ paddingBottom: 0 }}
        cancelable={() => { }}
        action={[{ text: 'OK', onPress: () => onCloseAlert() }
        ]}
      />

      {/* ----Snackbar---- */}
      <Snackbar open={snackbarMessage ? true : false} autoHideDuration={6000} onClose={() => setSnackbarMessage("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarMessage("")} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>


      <DialogContent>
        <TextInput
          disabled={props.loading}
          autoFocus={true}
          label="Enter Your Username"
          value={state.username}
          errorText={state.errorusername}
          onChange={(e) => setState({ ...state, username: e.target.value })}
        />
      </DialogContent>

      <DialogActions style={{ margin: 10 }}>
        {/* <div style={{ marginTop: 20, visibility: state.username.length > 0 ? 'visible' : 'hidden' }}> */}
        <Button
          disabled={props.loading}
          round
          fullWidth
          variant="contained"
          color="success"
          onClick={onSendOTP}>
          Continue
          </Button>
        {/* </div> */}
      </DialogActions>
    </React.Fragment>
  )
}

export default VerifyUsername;