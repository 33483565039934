import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

export const fetchAppConfigsStart=()=>{
    return{
         type:actionTypes.FETCH_APPCONFIGS_START
    }
}

export const setAppConfigs= (configs) => {
    return {
        type: actionTypes.SET_APPCONFIGS,
        appConfigs:configs
    }
}

export const fetchAppConfigsFailed=()=>{
    return {
        type:actionTypes.FETCH_APPCONFIGS_FAILED
    }
}

export const initAppConfigs = () => {
// console.log("-----initAppConfigs-----");
    return dispatch => {
        dispatch(fetchAppConfigsStart())
        axiosConfig().get('/QuestUser/GetConfig', {
            params: {
                AppId: 1 ,
            }
        }).then(response => {  
           dispatch(setAppConfigs(response.data))
        })
        .catch(function (error) {
           dispatch(fetchAppConfigsFailed())
        });
    }
}


export const setAppType= (AppType) => {
    return {
        type: actionTypes.SET_APPTYPE,
        payload:AppType
    }
}

