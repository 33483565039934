import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
// import axios
import axiosConfig from "util/axios";
//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'

// nodejs library to set properties for components
import PropTypes from "prop-types";

// import material-ui/core
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Hidden from "@material-ui/core/Hidden";
import { Input } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

//@material-ui/icons
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import GetAppIcon from '@material-ui/icons/GetApp';
//Custom Components
import Button from 'components/CustomComponents/CustomButtons/Button'
import Loading from 'components/CustomComponents/Progress/Progress'

//import style
import styles from "assets/jss/components/customcomponents/PDFViewStyle.js";
import './PDFViewStyle.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'assets/css/loader.css';
import Progress from 'components/CustomComponents/Progress/Progress';
import PinchToZoom from 'react-pinch-and-zoom';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles(styles);



export default function PDFViewer(props) {
    const {
        fileurl,
        password,
        ...rest
    } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [inputNumber, setInputNumber] = useState(1);
    const [pagScale, setPagScale] = useState(1);
    const [isLoading, setLoading] = useState(false)


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleNext = () => {
        setPageNumber((prevActiveStep) => prevActiveStep + 1);
        setInputNumber((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setPageNumber((prevActiveStep) => prevActiveStep - 1);
        setInputNumber((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (event) => {
        setInputNumber(parseInt(event.target.value));
    };

    const handleZoomin = () => {
        if (pagScale < 4)
            setPagScale((prevActiveStep) => prevActiveStep + 0.5);
    };

    const handleZoomout = () => {
        if (pagScale > 0.5)
            setPagScale((prevActiveStep) => prevActiveStep - 0.5);
    };

    const handleKeyPress = (e) => {
        const value = e.target.value
        const charCode = e.charCode;
        if (charCode == 13) {
            if (value > 0 && value <= numPages) {
                setPageNumber(parseInt(value))
                setInputNumber(parseInt(value));
            } else {
                setPageNumber(1)
                setInputNumber(1);
            }
        }

    }

    const handleDownload = (uri, fileName) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.downloadPdfFile(uri);
            dispatch(questActions.contentDownload(props.contentId));
        }
        else if (typeof onRemenberMeClicked !== 'undefined') {
            window.getPDFDownloadURL(uri);
            dispatch(questActions.contentDownload(props.contentId));
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('downloadPdfFile', uri, '');
            dispatch(questActions.contentDownload(props.contentId));
        }
        else {
            // saveAs(uri, `${fileName}.pdf`);
            setLoading(true)
            axiosConfig().get('/QuestContent/GetDocument', {
                params: {
                    ContentCode: props.contentId,
                }
            }).then(response => {
                setLoading(false)
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = `${fileName}.pdf`;
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                dispatch(questActions.contentDownload(props.contentId))
                // Remove link from body
                document.body.removeChild(link);

            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    return (

        <div className="center-container ">

            <Loading open={isLoading} ></Loading>
            {/*typeof onRemenberMeClicked !== 'undefined' && */
                <Fab aria-label="add" className={classes.fab} size="small" onClick={() => handleDownload(props.fileurl, props.fileName)}>
                    <GetAppIcon fontSize="small" />
                </Fab>
            }
            <div className={classes.pdfControl}>
                <Button justIcon color="transparent" size="lg" round onClick={handleBack} disabled={pageNumber === 1}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft />}
                </Button>
                <p>Page :</p>
                <Input
                    classes={{ input: classes.inputStyle }}
                    value={inputNumber}
                    type="number"
                    size="small"
                    onChange={handleChange}
                    onKeyPress={handleKeyPress} />

                <p> of {numPages}</p>
                <Button justIcon color="transparent" size="lg" round onClick={handleNext} disabled={pageNumber === numPages}>
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight />}
                </Button>
                {/* <Button justIcon color="transparent" size="lg" onClick={handleZoomout}><ZoomOutIcon></ZoomOutIcon></Button>
                <Button justIcon color="transparent" size="lg" onClick={handleZoomin}><ZoomInIcon></ZoomInIcon></Button>  */}
                <Hidden smDown implementation="css">
                    <Button justIcon color="transparent" size="lg" onClick={handleZoomout}><ZoomOutIcon></ZoomOutIcon></Button>
                    <Button justIcon color="transparent" size="lg" onClick={handleZoomin}><ZoomInIcon></ZoomInIcon></Button>
                </Hidden>
            </div>
            <PinchToZoom>
                <Document
                    file={{ url: props.fileurl }}
                    className={"PDFDocument"}
                    onPassword={(callback) => callback(password)}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<div className="loader">Loading...</div>}

                >
                    <Page pageNumber={pageNumber} className={"PDFPage"} scale={pagScale} />

                </Document>
            </PinchToZoom>
        </div>
    );
}


PDFViewer.propTypes = {
    fileurl: PropTypes.string,
    password: PropTypes.string,
};