import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

export const setNotificationData = (notifications) => {
    return {
        type: actionTypes.SET_NOTIFICATION,
        notifications: notifications
    }
}

export const fetchNotification = (Month, Year) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/GetNotification', {
            params: {
                UserId: UserId,
                Month: Month,
                Year: Year
            }
        }).then(response => {
            dispatch(setNotificationData(response.data))
        }).catch(function (error) {
            console.log(error);
            // dispatch(fetchSharedDataFailed())
        });
    }
}
