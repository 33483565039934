import React, { useState, useEffect } from "react";

// custom components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import LinearProgress from '@material-ui/core/LinearProgress';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as messageActions from "components/Views/QueryBox/store/actions/index"

// Views
import Inbox from './Inbox'

// Styles
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    listGridContainer: {
        display: 'flex',
        justifyContent: "center",
        transform: 'translate(0%, 0px)',

    },

}));


const MailContainer = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [loading, setLoading] = useState(false)
    const inboxLoading = useSelector(state => state.messages.loading)

    useEffect(() => {
        fetchMessageAnnouncements()
    }, [])

    const fetchMessageAnnouncements = () => {
        setLoading(true)
        dispatch(messageActions.fetchMessageAnnouncements()).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }


    return (
        <div className='querybox-container background-home'>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Inbox loading={loading} />
                    {inboxLoading ? <LinearProgress color="secondary" /> : null}
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default MailContainer