import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
//Redux
import { connect, useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'
// import Button from '@material-ui/core/IconButton';
import VideoViewer from 'components/Views/ContentViewer/VideoViewer/VideoViewer'

// import TopicCards from 'components/Views/Tasks/Topics/TopicCards'
import AutoPlaySwipeableViews from 'components/Views/Tasks/Topics/TaskSwipeableViews'
import TopicAccordion from 'components/Views/Tasks/Topics/TopicAccordion'

// style
import useStyles from 'assets/jss/components/views/topicsContainerStyle'

//Common functions
import { clevertapEvents, facebookTracking } from "util/commonfunctions";

const TaskCard = (props) => {
    const { topics } = props
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <AutoPlaySwipeableViews topics={topics} setContentType={props.setContentType} />
        </div >
    )
}

function WebTopics(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const mission = useSelector(state => state.content.current_mission)
    const quest = useSelector(state => state.quest.current_quest)
    const content = useSelector(state => state.content.contentlist);
    const operation = useSelector(state => state.content.current_operation)
    const questContentsList = useSelector(state => state.content.contentlist.add.questContents.filter((questContent) => (operation.operationId === questContent.operationId) && questContent["taskId"] === undefined))
    const taskList = useSelector(state => state.content.contentlist.add.tasks.filter((task) => operation.operationId === task.operationId))


    const [isVideo, setIsVideo] = React.useState(false);
    const [ScreenOrientation, setScreenOrientation] = React.useState('portrait')


    const handleChange = (taskId, panel) => (event, newExpanded) => {
        dispatch(questActions.setExpandedFlag(taskId, newExpanded))
    };




    useEffect(() => {
        if (questContentsList.length) {
            const contentTypeCode = questContentsList[0].contentTypeCode;
            if (contentTypeCode === '01' || contentTypeCode === '02' || contentTypeCode === '03') {
                clevertapfacebook(contentTypeCode)
                dispatch(questActions.setCurrentContent(questContentsList[0]))
                setIsVideo(true)
            }
        } else if (taskList.length) {
            dispatch(questActions.setExpandedFlag(taskList[0].taskId, true))
            const contentList = content.add.questContents.filter(questContent => taskList[0].taskId === questContent.taskId)
            if (contentList.length) {
                const contentTypeCode = contentList[0].contentTypeCode;
                if (contentTypeCode === '01' || contentTypeCode === '02' || contentTypeCode === '03') {
                    clevertapfacebook(contentTypeCode)
                    dispatch(questActions.setCurrentContent(contentList[0]))
                    setIsVideo(true)
                }
            }
        } else {
            dispatch(questActions.setCurrentContent({}))
            setIsVideo(false)
        }
    }, [operation.operationId])

    const clevertapfacebook = (contentTypeCode) => {
        //clevertap
        const eventData = JSON.stringify({
            'QuestName': quest.questName,
            'MisssionName': mission.missionDisplayName,
            'OperationName': operation.operationDisplayName,
            'ContentType': contentTypeCode
        });

        clevertapEvents('ContentView', eventData);
        //facebook tracking
        const EventProperty = JSON.stringify({
            'fb_content_type': contentTypeCode,
            'fb_content_id': mission.missionName,
            'fb_currency': ''
        })

        console.log(EventProperty);
        facebookTracking('fb_mobile_content_view', EventProperty)
    }

    return (
        <div className={classes.root}>

            {typeof onRemenberMeClicked === 'undefined'
                ? <VideoViewer
                    orientation={ScreenOrientation}
                    isVideo={isVideo}
                    onChangeOrientation={setScreenOrientation}
                    isWeb={true}
                />
                : null
            }


            <div className={classes.accordionDiv} >{/* style={{ height: isVideo ? '35vh' : '45vh' }} */}
                {questContentsList.length
                    ? <TopicAccordion
                        expanded={questContentsList.reduce((boolean, next) => next.isExpanded || boolean, false)}
                        onChange={handleChange('', 'panel' + operation.operationId)}
                        heading='Introduction'
                        accordionDetails={
                            <TaskCard task={""}
                                topics={questContentsList}
                                setContentType={typeof onRemenberMeClicked === 'undefined' ? setIsVideo : () => history.push('/player')}
                            />}
                    />
                    : null}
                {taskList.length
                    ? taskList.map((current_task, indx) =>
                        <TopicAccordion
                            key={`${current_task.operationId}${current_task.taskId}`}
                            expanded={current_task.isExpanded}
                            onChange={handleChange(current_task.taskId, `${current_task.operationId}${current_task.taskId}`)}
                            heading={current_task.taskDisplayName}
                            accordionDetails={<TaskCard
                                setContentType={typeof onRemenberMeClicked === 'undefined' ? setIsVideo : () => history.push('/player')}
                                key={current_task.taskId}
                                task={current_task}
                                topics={content.add.questContents.filter((questContent) => current_task.taskId === questContent.taskId)} />}
                        />
                    ) : null
                }

            </div>
        </div>
    );
}


export default WebTopics;

