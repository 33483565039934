import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  blackColor
} from "assets/jss/material-dashboard-react.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    borderRadius: 0
  },

  cardHeader: {
    margin:'0px !important',
    "&$cardHeader svg": {
      width: 30
    },
  },
  cardIconStyle: {
    boxShadow:'none !important',
    borderRadius: '0px !important',
      padding: "10px !important",
    // maxHeight: 55,
    // maxWidth: 55,
    marginTop: '0px !important'
  },
  cardCategoryDiv: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    justifyContent:'center'
  },
  cardCategory: {
    color: blackColor,
    fontWeight: 'bold',
    margin: "0",
    fontSize: "16px",
    marginTop: "0",
    // paddingTop: "10px",
    marginBottom: "0"
  },
  cardFooter: {
    margin: '0 10px 0px 0px',
    textAlign: 'end'
  },
  stats: {
    color: blackColor,
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },

}));

export default useStyles;