import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
      },
    formControl:{
        marginTop:10
    },
    chip: {
        margin: theme.spacing(0.2),
         borderRadius:2,
         maxWidth:400,
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         '-webkit-line-clamp': '1',
         '-webkit-box-orient': 'vertical',
         [theme.breakpoints.down('sm')]: {
            maxWidth:'-webkit-fill-available',
        },
      },
      
}));

export default useStyles;