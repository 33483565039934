import React, { useEffect, useState } from 'react';
import moment from 'moment'

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//Custom Components
import ShowMoreText from 'react-show-more-text';
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import MonthYearSelector from "components/CustomComponents/MonthYearSelector/MonthYearSelector";
import NotificationCard from 'components/Views/Notification/NotificationCard'
// custom style
import useStyles from "assets/jss/components/views/NotificationStyle";



const Connnect = (props) => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notificationcenter.notifications)
    const classes = useStyles();
    const [loading, setLoading] = useState(false)

    const [monthyear, setMonthYear] = useState(new Date())

    useEffect(() => {
        const MONTH = moment(monthyear).format("MM");
        const YEAR = moment(monthyear).format("YYYY");
        setLoading(true)
        dispatch(questActions.fetchNotification(MONTH, YEAR)).then(() => {
            setLoading(false)
        })
    }, [monthyear])

    return (
        <div className='page-container background-home'>
            <GridContainer className={classes.listGridContainer}>
                <GridItem xs={12} sm={12} md={6} >
                    <div style={{ padding: 30 }} >
                        <MonthYearSelector onChange={(value) => setMonthYear(value)} value={monthyear} />
                        <div style={{ height: '65vh', overflow: "overlay", }} className='scrollNotification'>
                            <NotificationCard notifications={notifications} loading={loading}></NotificationCard>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default Connnect;