import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import styles from "assets/jss/components/customcomponents/cardHeaderStyle.js";

const useStyles = makeStyles(styles);

export default function CustCardHeader(props) {
  const classes = useStyles();
  const { className,titleClassName, children, color, plain, stats, icon, title,onClose, ...rest } = props;
  let actionClose = null;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined
  });

  const titleClasses = classNames({
    [classes.cardTitle]: true,
    [className]: className !== undefined
  });


  if (onClose !== undefined) {
    actionClose = 
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.onClose()}
      >
        <Close className={classes.close} />
      </IconButton>
    ;
  }

  return (
    <div className={cardHeaderClasses} {...rest}>

      {title
        ? <div className={titleClasses} >
          <Hidden smDown implementation="js">
            <Typography variant="h6" component="h3">
             { title}
             
            </Typography>
          </Hidden>
          <Hidden mdUp implementation="js">
            <Typography variant="subtitle1" component="h3">
              {title}
            </Typography>
          </Hidden>
        </div>
        : null}
      {children}
      {actionClose}
    </div>

  );
}

// CustCardHeader.defaultProps={
//   titleClass:{}
// }

CustCardHeader.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  plain: PropTypes.bool,
  stats: PropTypes.bool,
  icon: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  onClose:PropTypes.func
};
