import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import userReducer from './userInfo'
import contentListReducer from './contentList'
import questListReducer from './userQuestList'
import testQuestionsReducer from './testQuestions'
import userProgressReducer from './userProgress'
import appConfigsReducer from './appConfigs'
import sharedDataReducer from './sharedData'
import leaderBoardReducer from './leaderBoard'
import notificationCenterReducer from './notificationCenter'
import questCategoryReducer from './questCategory'
import questUserRegistration from './userRegistration'
import questStoreReducer from './questStore'
import resetPasswordReducer from "./resetPassword";
import questProductsReducer from './questProducts';
import platformReducer from './platform';
import boardGradeReducer from './boardGrade'
import ProductBannerReducer from './productBanner'
import onlineClassesReducer from 'components/Views/Connect/store/reducers/reducer-online-classes'
import mssionsSchedulesReducer from 'components/Views/MissionsSchedules/store/reducers/reducer-mssions-schedules'

import queryReducer from 'components/Views/QueryBox/store/reducers/messageReducer'
// persist Config
const persistConfig = {
    key: 'root',
    storage: sessionStorage,
};

const appReducer = combineReducers({
    user: userReducer,
    newuser: questUserRegistration,
    content: contentListReducer,
    quest: questListReducer,
    questions: testQuestionsReducer,
    userprogress: userProgressReducer,
    appconfigs: appConfigsReducer,
    shareddata: sharedDataReducer,
    leaderboard: leaderBoardReducer,
    notificationcenter: notificationCenterReducer,
    categories: questCategoryReducer,
    store: questStoreReducer,
    resetpassword: resetPasswordReducer,
    products: questProductsReducer,
    platforms: platformReducer,
    boardgrade: boardGradeReducer,
    onlineclasses: onlineClassesReducer,
    schedules: mssionsSchedulesReducer,
    banner: ProductBannerReducer,
    messages:queryReducer,
});




const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        Object.keys(state).forEach(key => {
            sessionStorage.removeItem(`persist:${key}`);
        });
        state = undefined;
    }

    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)