
import React from 'react';

//@material-ui/core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Button from "components/CustomComponents/CustomButtons/Button.js";
// custom style
import useStyles from "assets/jss/components/views/questCategoryStyle";
import DefaultImage from "assets/img/task/default.png";

const SwipeCard = (props) => {
    const classes = useStyles();

    const isSamePrice = (Math.floor(props.data.discountPrice) === Math.floor(props.data.price))

    return (
        <GridContainer className={classes.webTopicCard}>
            <GridItem xs={12} sm={12} md={12} className={classes.topicCardGrid} >
                <Card className={classes.contentCardStyle}>
                    <CardActionArea onClick={() => props.onClick(props.data)} style={{ display: 'flex' }}>
                        <CardMedia
                            className={classes.cardMedia}
                            component="img"
                            alt={props.name}
                            // height="140"
                            // width='50%'
                            image={props.image ? props.image : DefaultImage}
                            title={props.name}
                        />
                    </CardActionArea>
                    <CardContent >
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <Typography variant="subtitle2" component="h2" className={classes.contentCardTitle}>
                                {props.name}
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', justifyItems: 'baseline' }}>
                                <samp className={classes.hyperText} onClick={() => props.onSeeMore(props.data)}>
                                    Details
                                </samp>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                                    <div className={classes.priceTextContainer}
                                        style={{ visibility: ((props.data.isFree || props.data.isAssigned) && !props.data.isTrialTaken ? 'hidden' : "visible") }}>
                                        <Typography variant="caption" className={classes.priceText} >
                                            <samp style={{ fontWeight: "normal" }}>₹</samp>{Math.floor(props.data.discountPrice) + ''}
                                        </Typography>
                                    </div>

                                    <div className={classes.priceTextContainer}
                                        style={{ visibility: (((props.data.isFree || props.data.isAssigned) && !props.data.isTrialTaken || isSamePrice) ? 'hidden' : "visible") }}>
                                        <Typography variant="caption" className={isSamePrice ? classes.priceText : `line-through ${classes.priceText}`} >
                                            <samp style={{ fontWeight: "normal" }}>₹</samp>{Math.floor(props.data.price) + ''}
                                        </Typography>
                                    </div>


                                </div>

                            </div>

                            {props.data.isFree
                                ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                    <div></div>
                                    {
                                        props.data.isOnline === true || props.data.isOnline === undefined || props.data.isOnline === null
                                            ? <Button className={classes.button} fullWidth style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(props.data)}  >Launch</Button>
                                            : <Button className={classes.button} color="info" size="xs" onClick={() => props.onOfflineLaunch(props.data.formUrl)} >I am interested</Button>
                                    }

                                </div>
                                : props.data.isTrialTaken
                                    ?
                                    // props.data.isAssigned
                                    //     ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                    //         <Button style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(props.data)}  >Launch</Button>
                                    //     </div>
                                    //     : 

                                    <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                        <div></div>
                                        <Button className={classes.button} fullWidth style={{ width: '40%' }} label='Buy' color="rose" size="xs" onClick={() => props.onBuy(props.data)}  >Buy</Button>
                                    </div>
                                    : props.data.isAssigned
                                        ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button className={classes.button} style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(props.data)}  >Launch</Button>
                                        </div>
                                        : props.data.isOnline === true || props.data.isOnline === undefined || props.data.isOnline === null
                                            ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: -15 }}>
                                                {props.data.trialDuration > 0
                                                    ? < Button  className={classes.button} fullWidth style={{ marginRight: 3, marginLeft: -1 }} color="warning" size="xs" onClick={() => props.onTrail(props.data)}  >Try</Button>
                                                    : <div style={{ width: '100%' }}></div>
                                                }
                                                <Button  className={classes.button} fullWidth style={{ marginLeft: 3 }} color="rose" size="xs" onClick={() => props.onBuy(props.data)}  >Buy</Button>
                                            </div>
                                            : <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button className={classes.button}  color="info" size="xs" onClick={() => props.onOfflineLaunch(props.data.formUrl)} >I am interested</Button>
                                            </div>
                            }
                        </div>
                    </CardContent>
                </Card>

                <Typography variant="caption" component="h2" className={classes.freeText} style={{ backgroundColor: props.data.tagColor }}>
                    {props.data.tagValue}
                </Typography>
            </GridItem>
        </GridContainer >

    )
};

export default SwipeCard;