import React from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Drawer from 'components/CustomComponents/Drawer/Drawer';
// Views
import Dashboard from 'components/Views/Dashboard/Dashboard'
import HomeDefaultComponent from 'components/Views/Home'
import Profile from "components/Views/Profile/Profile"
import Connect from "components/Views/Connect"
import Notification from 'components/Views/Notification/Notification'
import OnlineClasses from 'components/Views/Connect/views/OnlineClasses'

import QueryBox from 'components/Views/QueryBox/views'

// styles
import styles from "assets/jss/components/views/homeStyle";

// img
import QuestLogo from "assets/img/icons/Questlogoheader.png";

const Routes = [
  {
    path: "/home/querybox",
    name: "QueryBox",
    icon: null,
    component: QueryBox,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/home/connect",
    name: "Connect",
    icon: null,
    component: OnlineClasses,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/home/dashboard",
    name: "Dashboard",
    icon: null,
    component: Dashboard,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/home/profile",
    name: "profile",
    icon: null,
    component: Profile,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/home/notification",
    name: "Notification",
    icon: null,
    component: Notification,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/home",
    name: "Home",
    icon: null,
    component: HomeDefaultComponent,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  


]


const SwitchRoutes = (props) => (
  <Switch location={props.location}>
    {Routes.map((prop, key) => {
      if (prop.submenu.length)
        return prop.submenu.map(submenu => <Route
          path={submenu.layout + submenu.path}
          component={submenu.component}
          key={key + submenu.name} />)
      return <Route
        path={prop.layout + prop.path}
        component={prop.component}
        key={key + prop.name}
      />
    })}
  </Switch>
);

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
      mobileOpen: false,
      prevDepth: this.getPathDepth(this.props.location)
    }
  }

  componentWillReceiveProps = () => {
    this.setState({ prevDepth: this.getPathDepth(this.props.location) })
  }


  getPathDepth = (location) => {
    let pathArr = location.pathname.split("/")
    pathArr = pathArr.filter(n => n !== "")
    return pathArr.length
  }

  render() {
    const { classes, location } = this.props;
    const currentKey = location.pathname.split("/")[2] || "/";
    return (

      <div >
        <Drawer logo={QuestLogo} />

        <TransitionGroup component='div' className={classes.container}>
          <CSSTransition
            key={currentKey}
            addEndListener={(node, done) => { node.addEventListener("transitionend", done, false); }}
            classNames="fade" >
            <div className={this.getPathDepth(location) - this.state.prevDepth >= 0 ? "left" : "right"}>
              <SwitchRoutes location={location} />
            </div>
          </CSSTransition>
        </TransitionGroup>

      </div>

    )
  }
}



export default withRouter(withStyles(styles)(Home));