import React from 'react';
// custom style
import useStyles from "assets/jss/components/views/connectStyle";
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';


const Connnect = (props) => {
    const classes = useStyles();

    return (
        <div className='page-container background-home'>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <div className={classes.form}>
                        <div id="alert-dialog-title">{"Singhania Quest+ App is not available on browsers. Please download Singhania Quest+ App from Google Playstore for Android devices and for iOS devices download it from Apple Appstore."}</div>
                    </div>
                </Paper>
            </main>
        </div>
    );
}

export default Connnect;