import React, { useEffect } from 'react';


import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

//@material-ui/icons
import PersonIcon from '@material-ui/icons/HowToReg';
// import HowToRegIcon from '@material-ui/icons/HowToReg';
import ShareIcon from '@material-ui/icons/Share';
//Custom Components
import LinearProgress from '@material-ui/core/LinearProgress';
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import Button from "components/CustomComponents/CustomButtons/Button.js";
import StyledBadge from "components/CustomComponents/StyledBadge/StyledBadge";

import useStyles from "assets/jss/components/views/friendListStyle"

export default function SimpleDialogDemo(props) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data])


  const handleClose = () => {
    props.onClose()
  };

  const handleToggle = (selectedValue) => () => {
    let newChecked = [...data];
    newChecked = newChecked.map(currentValue => {
      let newCurrentValue = { ...currentValue }
      if (newCurrentValue.userId === selectedValue.userId)
        newCurrentValue.isShared = !selectedValue.isShared
      return newCurrentValue

    })
    setData(newChecked);
  };




  return (
    <React.Fragment>
      {data.length === 0 ?
        <AlertDialog
          maxWidth="xs"
          open={props.open}
          type={"info"}
          ContentText={"Friends list not available"}
          ContentTextStyle={{ color: 'black', marginBottom: 0 }}
          title={null}
          titleStyle={{ paddingBottom: 0 }}
          cancelable={() => { }}
          action={[{ text: 'OK', onPress: () => handleClose() }
          ]}
        />
        : <React.Fragment>
          <DialogTitle id="simple-dialog-title">Share with friends</DialogTitle>
          <DialogContent className={classes.dialogContentStyle}>
            <List dense className={classes.listStyle}>   
                {data.map((value) => (
                  <ListItem button  disabled={props.loading ? true : false} onClick={handleToggle(value)} key={value.userId} className={classes.listItemStyle}>
                    <ListItemAvatar className={classes.listItemAvatarStyle}>
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        variant={value.isShared ? "dot" : null}>
                        <Avatar className={classes.avatar}>
                          <PersonIcon fontSize="small" />
                        </Avatar>
                      </StyledBadge>
                    </ListItemAvatar>
                    <ListItemText disableTypography={true}
                      primary={
                        <Typography className={classes.heading}>
                          {value.name}
                        </Typography>
                      } />

                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(value)}
                        checked={value.isShared}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}

            </List>
          </DialogContent>
          <DialogActions style={{ margin: 10 }}>
            <Button
              disabled={props.loading ? true : false}
              round
              outlined
              fullWidth
              color="primary"
              size='md'
              onClick={handleClose}
            >
              Not now
        </Button>
            <Button
              disabled={props.loading ? true : false}
              startIcon={<ShareIcon />}
              round
              fullWidth
              color="primary"
              size='md'
              onClick={() => props.onPost(data)}
            >
              Share
       </Button>

          </DialogActions>
          {props.loading ? <LinearProgress color="secondary" /> : null}
        </React.Fragment>
      }
    </React.Fragment>

  );
}