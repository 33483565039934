import React, { useEffect, useState } from "react";

//Redux
import { connect } from 'react-redux'

import { Doughnut } from 'react-chartjs-2';

//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Body from "components/CustomComponents/Card/CardBody.js";
import Button from "components/CustomComponents/CustomButtons/Button.js";


// styles
import Styles from "assets/jss/components/views/assessmentStyle";
const useStyles = makeStyles(Styles);


const QuestionAnswers = (props) => {
    const classes = useStyles();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const skipCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isQuestionSkipped === "0" ? 1 : 0), 0)
    const rightCount = props.answers.reduce((Count, currentValue) => Count + parseInt(currentValue.isAnsweredCorrect), 0)
    const wrongCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isAnsweredCorrect === "0" && currentValue.isQuestionSkipped !== "0" ? 1 : 0), 0)
    const marksObtained = props.answers.reduce((Count, currentValue) => Count + parseFloat(currentValue.questionMarksObtained), 0)
    const data = {
        datasets: [{
            data: [skipCount, rightCount, wrongCount],
            backgroundColor: ['#A9A9A9', '#51D887', '#FF0000']
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: ['Skip', 'Correct', 'Incorrect'],
    };

    const legend = {
        position: "bottom",
    }

  

    useEffect(() => {
        if (props.test.testAttemptedDuration > 0) {
            let totalSeconds = Number(props.test.testAttemptedDuration);
            totalSeconds %= 3600;
            const newMinutes = Math.floor(totalSeconds / 60);
            const newSeconds = totalSeconds % 60;
            setMinutes(newMinutes)
            setSeconds(newSeconds)
        }
    }, [])

    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={12} sm={12} md={10} className={classes.centerContainer}>
                    {props.loading
                        ? <Skeleton className={classes.centerContainer} animation="wave" variant="circle" width={'15rem'} height={'15rem'} />
                        : <Doughnut data={data} legend={legend} ></Doughnut>
                    }
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.centerContainer} >
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <div style={{ width: props.loading ? '100%' : "" }}>
                        <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Score : ${marksObtained + " / " + props.test.totalMark}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Correct : ${rightCount}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Incorrect : ${wrongCount}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Skipped : ${skipCount}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Attempts : ${props.content.progress.accessCount + 1}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Time Taken : ${minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds)} minutes`}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </React.Fragment>
    )
}


const mapSateToProps = state => {
    return {
        loading: state.questions.post_test_loading,
        content: state.content.current_content,
        test: state.content.current_test,
        answers: state.questions.answers,
    }
}

const mapDispatchToProps = dispatch => {
    return {


    }
}


export default connect(mapSateToProps, mapDispatchToProps)(QuestionAnswers);
