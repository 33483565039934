import React from "react";
//redux
import { connect } from 'react-redux'

// @material-ui/core
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// @material-ui/icons
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import CardIcon from "components/CustomComponents/Card/CardIcon.js";
import CardFooter from "components/CustomComponents/Card/CardFooter.js";
import Info from 'components/CustomComponents/Typography/Info'

// img
import avatar from "assets/img/climbing.png";

// styles
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import styles from "assets/jss/components/views/instructionStyle";

const useStyles = makeStyles(styles);

const GreenCheckbox = withStyles({
    root: {
        color: primaryColor[4],
        '&$checked': {
            color: primaryColor[4],
        },
    },
    checked: {},
})((props) => <Checkbox
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<CheckCircleIcon />}
    color="default" {...props} />);


const Instruction = (props) => {
    // const [progress, setProgress] = React.useState(false);
    const classes = useStyles();
    const [show_instruction, setShowInstruction] = React.useState(false);
    const handleCheckChange = (event) => {
        setShowInstruction(event.target.checked);
    };

    const {
        quest,
        onClose,
        onStart,
        onBuy
    } = props

    if (quest.isTrialOver) {
        return (
            <React.Fragment>
                <DialogTitle id="responsive-dialog-title">{"Trial Expired"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your trial has been expired. Please buy the product to get access of contents.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size='md' round outlined color="primary" onClick={onClose}>
                        Not now
                    </Button>
                    <Button size='md' round color="rose" onClick={() => {
                        onClose()
                        onBuy(quest)
                    }}>
                        Buy now
                    </Button>
                </DialogActions>
            </React.Fragment >
        )
    }


    return (
        <React.Fragment>
            {Object.keys(props.mission).length ?
                <React.Fragment>
                    <DialogTitle>
                        <GridContainer>
                            <GridItem xs={4} sm={4} md={4}>
                                <CardIcon className={classes.imgIcon}>
                                    <img className={classes.imageStyle} src={avatar} alt="..." />
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} >
                                <CardFooter className={classes.stats} >
                                    <Typography variant='h6' component='h2' style={{ lineHeight: 2, fontWeight: 'bold' }} className={classes.infoText}>{props.mission.missionDisplayName}</Typography>
                                    <div style={{ flexDirection: "row", display: 'flex', justifyContent: 'center' }}>
                                        <div className={classes.taskDiv}>
                                            <Info >Tasks</Info>
                                            <Info>{props.mission.videoCount + props.mission.testCount + props.mission.activityCount + props.mission.webContentCount}</Info>
                                        </div>
                                        <div className={classes.timeDiv}>
                                            <Info>Time</Info>
                                            <Info>{props.mission.totalTime}</Info>
                                        </div>
                                    </div>
                                </CardFooter>
                            </GridItem>
                        </GridContainer>
                    </DialogTitle>

                    <DialogContent>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>

                                <div className={classes.earnPoints} color="primary">
                                    <div className={classes.timeDiv}>
                                        <Typography variant='caption' component='h2' style={{ lineHeight: 1 }} className={classes.infoText}>You can earn</Typography>
                                        <Typography variant='subtitle1' component='h2' className={classes.infoText} style={{ fontWeight: 'bold' }}>{props.mission.points} Points</Typography>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} className={classes.infoText}>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Typography variant="subtitle1" component='h3' gutterBottom style={{ fontWeight: 'bold' }}>What is the mission?</Typography>
                                        {/* <Typography variant='caption' cdisplay="block">
                                                          {props.mission.missionDescription}
                                        </Typography> */}
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: props.mission.missionDescription
                                            }}
                                        ></div>
                                    </GridItem>
                                </GridContainer>
                                <br></br>
                                {/* <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Typography variant="subtitle1" component='h3' gutterBottom style={{ fontWeight: 'bold' }}>Do & Don't</Typography>
                                        <Typography variant='caption' display="block" gutterBottom>
                                            View full video to earn 10 points
                                            </Typography>
                                        <Typography variant='caption' display="block" gutterBottom>
                                            View full video to earn 10 points
                                            </Typography>
                                        <Typography variant='caption' display="block" gutterBottom>
                                            View full video to earn 10 points
                                            </Typography>
                                    </GridItem>
                                </GridContainer> */}

                                {/* <br></br>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControlLabel
                                            className={classes.checkboxStyle}
                                            control={<GreenCheckbox checked={show_instruction} onChange={handleCheckChange} name="checkedG" />}
                                            classes={{ label: classes.checkboxLabelStyle }}
                                            label="Don't show again"
                                        />
                                    </GridItem>
                                </GridContainer> */}

                            </GridItem>
                        </GridContainer>
                    </DialogContent>

                    <DialogActions style={{ margin: 10 }}>

                        <Button
                            // disabled={progress ? true : false}
                            size='md'
                            round
                            outlined
                            fullWidth
                            color="primary"
                            onClick={onClose}>
                            Not now
                        </Button>
                        <Button
                            // disabled={progress ? true : false}
                            size='md'
                            round
                            fullWidth
                            color="primary"
                            onClick={() => onStart()}>
                            Let's Start
                            <ArrowForwardIcon fontSize="small" />
                        </Button>

                    </DialogActions>
                </React.Fragment>

                : null
            }
        </React.Fragment>
    );
}



const mapSateToProps = state => {
    return {
        mission: state.content.current_mission,
    }
}


export default connect(mapSateToProps, null)(Instruction);
