import React, { useState } from 'react';

//Redux
import { useSelector } from 'react-redux'

// import material component
// import LinearProgress from '@material-ui/core/LinearProgress';
import Progress from "components/CustomComponents/Progress/Progress"

//import Custom Components
import VerifyUsername from './VerifyUsername'
import ResetPassword from "./ResetPassword";

import { SwitchTransition, CSSTransition } from "react-transition-group";

// custom style
import useStyles from "assets/jss/components/views/forgotPasswordStyle";



const mode = "out-in"

const ForgotPassword = (props) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const steps = ['1', '2'];
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false)

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      props.onClose()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.onClose()
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <VerifyUsername handleNext={handleNext} onChangeTitle={props.onChangeTitle} handleBack={handleBack} handleLoading={setLoading} activeStep={activeStep} steps={steps} setUsername={setUsername} />
      case 1:
        return <ResetPassword handleNext={handleNext} onChangeTitle={props.onChangeTitle} handleBack={handleBack} handleLoading={setLoading} activeStep={activeStep} steps={steps}
          username={username} loading={loading} onResetPassword={props.onResetPassword} />
      default:
        return 'Unknown step';
    }
  }


  return (
    <React.Fragment >
      {/* {loading ? < LinearProgress color="primary" style={{ marginTop: -16, width: '100%' }} /> : null} */}
      <Progress open={loading} />
      <div className={classes.paper}>
        <SwitchTransition mode={mode}>
          <CSSTransition
            key={activeStep}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            {getStepContent(activeStep)}
          </CSSTransition>
        </SwitchTransition>

      </div>
    </React.Fragment >

  );
}

export default ForgotPassword;