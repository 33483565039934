import React, { useState } from 'react';
//Redux
import { useDispatch } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//Views
import VerifyOTP from "components/Views/VerifyOTP/VerifyOTP";

const CouponCode = (props) => {
    const { mobileNumber, onClose, onValidate } = props
    const dispatch = useDispatch();
    
    const [alertType, setAlertType] = React.useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const onValidateOTP = async (verificationCode) => {
        dispatch(questActions.validateOTP(verificationCode,mobileNumber)).then(() => {
            onValidate(true)
            onClose(false)
        }).catch(error => {
            setAlertType("error")
            setSnackbarMessage(error.message)
        });
    }

    const resendOTP = async () => {
        setLoading(true)
        dispatch(questActions.requestForOTP(mobileNumber)).then(() => {
            setLoading(false)
            setAlertType("success")
            setSnackbarMessage("OTP was resent successfully!")
        }).catch(error => {
            setAlertType("error")
            setSnackbarMessage(error.message)
        });
    }

    return (

        <React.Fragment>
            <VerifyOTP
                resendOTP={resendOTP}
                onValidateOTP={onValidateOTP}
                onClose={onClose}
                loading={loading}
                snackbarseverity={alertType}
                snackbarMessage={snackbarMessage}
                setSnackbarMessage={setSnackbarMessage}
                username={mobileNumber}
            />
        </React.Fragment>
    )
}

export default CouponCode;