import React, { useState, useEffect } from "react";

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as actions from "components/Views/MissionsSchedules/store/actions"

// custom components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
// Views
import Todays from './Todays'



// Styles
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },

}));


const Container = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();


    const [loading, setLoading] = useState(false)
    const [currentDataTime, setCurrentDataTime] = useState(new Date())

    const mission = useSelector(state => state.content.current_mission)
    const questContent = useSelector(state => state.content.contentlist)



    useEffect(() => {
        setLoading(true)
        let dispatchAction = actions.fetchMissionSchedules(mission.missionId)
        dispatch(dispatchAction).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });

        let interval = setInterval(() => setCurrentDataTime(new Date()), 1000);
        return (() => {
            clearInterval(interval);
        })


    }, [mission.missionId])


    return (
        <React.Fragment>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={mission.missionDisplayName} back={'/home'} />

            <div className='page-container' >
                <GridContainer className={classes.listGridContainer}>
                    <GridItem xs={12} sm={12} md={6} >
                        <div style={{ padding: '0px 10px' }} >
                            <Todays
                                currentdatetime={currentDataTime}
                                loading={loading}
                                operations={questContent.add.operations.filter(current_value => mission.missionId === current_value.missionId)}

                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </React.Fragment>
    )
}

export default Container