import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  // defaultFont
} from "assets/jss/material-dashboard-react.js";

const customInputStyle = {
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0]
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor[0]
    }
  },
  // labelRoot: {
  //   ...defaultFont,
  //   color: grayColor[2] + " !important",
  //   // fontWeight: "400",
  //   // fontSize: "0.875rem",
  //   fontWeight: "600",
  //   fontSize: "1rem",
  //   lineHeight: "1.42857",
  //   letterSpacing: "unset"
  // },  
  radioRoot: {
    fontSize: "0.75rem",
    fontWeight: "600",
  },
  labelRootError: {
    color: dangerColor[0],
    marginTop: 0
  },
  labelRootSuccess: {
    color: successColor[0]
  },
  lableError: {
    marginTop: 0
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: "16px"
  },
  // marginDense:
  // {
  //   marginBottom: '0px'
  // },
  formControl: {
    // paddingBottom: "10px",
    // margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    // marginBottom: '0px !import'
  },
  footerButton: {
    marginRight: '10px'
  }
};

export default customInputStyle;
