import {
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
  container,
  defaultFont,
  secondaryColor,
  brandColor,
  pagecontainer
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = theme => ({
  pagecontainer: {
    ...pagecontainer
  },
  container,
  questDiv: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    width: '100%'
  },
  questNameText: {
    lineHeight:1.2,
    fontWeight: "bold",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    // height: '3em',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  questButtonsDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  questButtons: {
    margin: 0
  },

  changeButton:{
    padding:' 0.3rem 0.5rem',
    fontSize: '0.563rem',
    lineHeight: 1,
    backgroundColor:brandColor[3]
  },

  buyButton:{
    backgroundColor:brandColor[1]
  },

  infoText: {
    color: primaryColor[4]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  pageCardBody: {
    minHeight: '100vh',
    margin: 0
  },
  gridItemHeader: {
    marginLeft: "5%"
  },

  gridItemFooter: {
    padding: '0 !important',
    color: primaryColor[4]
  },
  paperMissions: {
    borderRadius: 20,
    // backgroundColor: '#f9f8f9e1',
    // opacity: '90%'
  },
  gridItemMissions: {
    padding: 15
  },
  cardMissions: {
    backgroundColor: '#f5fffe',
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 248,
    marginBottom: "0px",
    borderRadius: 10,
    boxShadow: '0 5px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  textMissions: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 248,
    marginBottom: "-20px",

  },

  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardAvatar: {
    maxWidth: "80px",
    maxHeight: "80px",
    right: "10%",
    position: 'absolute',
    margin: "-40px auto 0",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0",
    // boxShadow:
    //   "0 16px 38px -12px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.56), 0 4px 25px 0px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.12), 0 8px 10px -5px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.2)",
    // "&$cardAvatarPlain": {
    //   marginTop: "0"
    // }
  },
  imageStyle: {
    width: "auto",
    height: "-webkit-fill-available"
  },

  '@media screen and (max-width: 600px)': {
    // gridItemMissions: {
    //   maxHeight: '100%',
    //   padding: "0px 15px"
    // },
    gridItemHeader: {
      paddingRight: '25px'
    }
  },

  /* Extra small devices (phones, 600px and down) */
  // '@media screen and (max-width: 600px)': {
  //   gridItemMissions: {
  //     maxHeight: '100%',
  //     padding: "0px 15px"
  //   },
  //   gridItemHeader: {
  //     paddingRight: '25px'
  //   }
  // },


  /* Small devices (portrait tablets and large phones, 600px and up) */
  // '@media screen and (min-width: 600px)': {
  //   gridItemMissions: {
  //     maxHeight: '100%',
  //     padding: "0px 15px"
  //   },
  //   gridItemHeader: {
  //     paddingRight: '25px'
  //   },
  // },
  /* Medium devices (landscape tablets, 768px and up) */
  // '@media screen and (min-width: 768px)': {
  //   gridItemMissions: {
  //     maxHeight: '100%',
  //     padding: "0px 15px"
  //   },
  //   gridItemHeader: {
  //     paddingRight: '25px'
  //   }
  // },

  /* Large devices (laptops/desktops, 992px and up) */
  // '@media only screen and (min-width: 992px)': {
  //   gridItemMissions: {
  //     maxHeight: '100%',
  //     padding: "0px 15px"
  //   },
  //   gridItemHeader: {
  //     paddingRight: '25px'
  //   }
  // },

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  // '@media screen and (min-width: 1200px)': {
  //   gridItemMissions: {
  //     maxHeight: '100%',
  //     padding: "0px 15px"
  //   },
  //   gridItemHeader: {
  //     paddingRight: '25px'
  //   }
  // }

  //---------------
  warningAlert: {
    position: "absolute",
    top: '30%',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    right: '10%',
    left: '10%'
  },
  square: {
    objectFit: 'fill',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  },
  buyButtonContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // background: '#1976d2',
    paddingLeft: 5,
    // paddingRight: 5,
    // borderRadius: 50

  },

  bottomCard: {
    // backgroundColor: '#f5fffe',
    backgroundImage: 'linear-gradient(135deg , rgb(0 171 85 / 58%) 0%, rgb(0, 184, 172) 100%)',
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('sm')]: {
      maxWidth: '95%',
    },
    marginBottom: "0px",
    borderRadius: 10,
    boxShadow: '0 5px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  bottomCardAction: {
    padding: 20
  },
  bottomCardTitle: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 'bold',
  }




});

export default dashboardStyle;
