import React, { useState } from 'react';
//axios
import axiosConfig from "util/axios";

// material component
import Avatar from '@material-ui/core/Avatar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import LockIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


//Custom Components
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import Button from "components/CustomComponents/CustomButtons/Button.js";

//validations
import { checkNull, checkPassword } from "util/validations";

// styles
import useStyles from "assets/jss/components/views/changePasswordStyle";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ChangePassword = (props) => {
    const classes = useStyles();
    const [progress, setProgress] = useState(false);

    const [errorState, setErrorState] = useState({
        open: false,
        errorMessage: ""
    });

    const [state, setState] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        errorCurrentPassword: "",
        errorNewPassword: "",
        errorConfirmPassword: "",
    })

    // ------Clear All States---------
    const clearStates = () => {
        setState({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            errorCurrentPassword: "",
            errorNewPassword: "",
            errorConfirmPassword: "",
            currentShowPassword: false,
            newShowPassword: false,
            confirmShowPassword: false,
        });
        setErrorState({
            open: false,
            errorMessage: ""
        });
        setProgress(false);
    }

    // ------Clear error---------
    const clearError = () => {
        setState({
            ...state,
            errorCurrentPassword: "",
            errorNewPassword: "",
            errorConfirmPassword: ""
        });
    }

    // ---- click chnage --------
    const handlePasswordChange = () => {
        clearError() // clear Error
        setProgress(true)
        // Validation
        let validationObject = {};
        checkNull("CurrentPassword", state.currentPassword, "Password is required", validationObject);
        checkNull("NewPassword", state.newPassword, "New Password is required", validationObject);
        checkNull("ConfirmPassword", state.confirmPassword, "Confirm Password is required", validationObject);
        checkPassword("ConfirmPassword", state.newPassword, state.confirmPassword, "Password not match.", validationObject);

        if (Object.keys(validationObject).length != 0) {
            setProgress(false)
            setState({
                ...state,
                errorCurrentPassword: validationObject.errorCurrentPassword,
                errorNewPassword: validationObject.errorNewPassword,
                errorConfirmPassword: validationObject.errorConfirmPassword,
            });
        } else {
            postChangePassword(state.currentPassword, state.newPassword)
        }
    }

    // axios call for post password change
    const postChangePassword = (oldPassword, newPassword) => {
        axiosConfig().get('/QuestUser/ChangePassword', {
            params: {
                oldPassword: oldPassword,
                newPassword: newPassword
            }
        })
            .then(response => {
                clearStates()
                props.onStart()
            }).catch((error) => {
                setProgress(false)
                if (error.response.data) {
                    setErrorState({
                        open: true,
                        errorMessage: error.response.data.error
                    })
                }

            });
    }

    // ------handle Close Snackbar----
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorState({
            open: false,
            errorMessage: ""
        });
    };

    // ----click Not now-----
    const handleDialogClose = () => {
        clearStates()
        props.onClose()
    }

    const handleClickShowPassword = (showPassword) => () => {
        console.log(showPassword);
        setState({ ...state, [showPassword]: !state[showPassword] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <React.Fragment>
            <DialogTitle id="form-Change-Password" className={classes.dialogTitle}>
                <Avatar className={classes.avatar}>
                    <LockIcon />
                </Avatar>
                Change Password
            </DialogTitle>
            <DialogContent>

                <TextInput
                    label="Current Password"
                    disabled={progress ? true : false}
                    type={state.currentShowPassword ? 'text' : 'password'}
                    value={state.currentPassword}
                    errorText={state.errorCurrentPassword}
                    onChange={(e) => setState({ ...state, currentPassword: e.target.value })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('currentShowPassword')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {state.currentShowPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextInput
                    label="New Password"
                    disabled={progress ? true : false}
                    type={state.newShowPassword ? 'text' : 'password'}
                    value={state.newPassword}
                    errorText={state.errorNewPassword}
                    onChange={(e) => setState({ ...state, newPassword: e.target.value })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('newShowPassword')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {state.newShowPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextInput
                    label="Confirm Password"
                    disabled={progress ? true : false}
                    type={state.confirmShowPassword ? 'text' : 'password'}
                    value={state.confirmPassword}
                    errorText={state.errorConfirmPassword}
                    onChange={(e) => setState({ ...state, confirmPassword: e.target.value })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('confirmShowPassword')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {state.confirmShowPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>


            <DialogActions style={{ margin: 10 }}>
                <Button
                    disabled={progress ? true : false}
                    round
                    outlined
                    fullWidth
                    color="primary"
                    onClick={handleDialogClose}>
                    Not now
                 </Button>
                <Button
                    disabled={progress ? true : false}
                    round
                    fullWidth
                    color="primary"
                    onClick={handlePasswordChange}>
                    Change
                </Button>

            </DialogActions>

            <Snackbar open={errorState.open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity="error">
                    {errorState.errorMessage}
                </Alert>
            </Snackbar>

            {progress ? <LinearProgress color="secondary" /> : null}

        </React.Fragment>
    )
}



export default ChangePassword;


