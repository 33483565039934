import React from 'react';


//import Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import CardBody from "components/CustomComponents/Card/CardBody.js";
import CardHeader from "components/CustomComponents/Card/CardHeader.js";
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import Button from "components/CustomComponents/CustomButtons/Button"

// custom style
import useStyles from "assets/jss/components/views/signupStyle";

const CouponCode = (props) => {
    const classes = useStyles();

    return (
        <GridContainer className={classes.stepContent}>
            <GridItem xs={12} sm={12} md={12}>
                <CardHeader
                    titleClassName={classes.titleHeader}
                    className={classes.cardHeader}
                    title={"Please enter Coupon code (optional)"}
                />

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextInput
                            id="MOBILE_NUMBER"
                            autoFocus={true}
                            label="Coupon Code"
                        //   value={this.state.WAREHOUSE_NAME}
                        //   errorText={this.state.erroWAREHOUSE_NAME}
                        //   onChange={(e) => this.setState({ WAREHOUSE_NAME: e.target.value })}
                        />

                        <div style={{ marginTop: 10 }}>
                            <Button fullWidth variant="contained" size="sm" color="success" onClick={() => { }}>
                                Apply
                                </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
                <div className={classes.actionsContainer}>
                    <Button
                        size="normal"
                        // disabled={props.activeStep === 0}
                        disabled
                        onClick={props.handleBack}
                        className={classes.button}
                    >
                        Back
                        </Button>
                    <Button
                        size="normal"
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleNext()}
                        className={classes.button}
                    >
                        {props.activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </div>
            </GridItem>
        </GridContainer>
    )
}

export default CouponCode;