import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    userinfo: {
        "userId": "",
        "userName": "",
        "firstName": "",
        "lastName": "",
        "emailId": "",
        "contactNo": "",
        "cityName": "",
        "stateName": "",
        "countryName": "",
        "isActive": true,
        "isDeleted": false,
        "institutionId": 1,
        "locationId": "",
        "profilePic": "",
        "groupId": "",
        "showDashboard": false,
        "userQuestList": [],
        "isSuccess": false,
        "extraBoolValue": false
    },
    error: false,
    userQuest: {},
    loading: true,
    post_password_error: false,
    post_password_loading: false,
    auth_error: false,
    user_type: 'ExistingUser'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERINFO_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_USERINFO:
            return {
                ...state,
                userinfo: action.userinfo,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_USERINFO_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        // ------- Set Profile Picture  -------------
        case actionTypes.SET_PROFILE_PICTURE:
            return {
                ...state,
                userinfo: { ...state.userinfo, profilePic: action.profilePic }
            }
        // ------- Post Change Passsword -------------
        case actionTypes.POST_CHANGE_PASSWORD_START:
            return {
                ...state,
                post_password_error: false,
                post_password_loading: true
            }

        case actionTypes.POST_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                post_password_error: false,
                post_password_loading: false
            }

        case actionTypes.POST_CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                post_password_error: true,
                post_password_loading: false
            }
        case actionTypes.AUTH_FAILED:
            return {
                ...state,
                auth_error: action.auth,
            }
        case actionTypes.SET_USER_TYPE:
            return {
                ...state,
                user_type: action.user_type,
            }
        default:
            return state;
    }


}


export default reducer