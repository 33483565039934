import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

export const setInitialUser = () => {
    return {
        type: actionTypes.INIT_REGISTER_USER
    }
}


export const setFLName = (firstName, lastName) => {
    return {
        type: actionTypes.SET_FLNAME,
        firstName: firstName,
        lastName: lastName
    }
}

export const setUsernamePassword = (userName, password) => {
    return {
        type: actionTypes.SET_USERNAME_PASSWORD,
        userName: userName,
        password: password
    }
}

export const setSchoolCity = (schoolName, cityName) => {
    return {
        type: actionTypes.SET_SCHOOLNAME_CITY,
        schoolName: schoolName,
        cityName: cityName
    }
}

export const setContactNumber = (contactNumber) => {
    return {
        type: actionTypes.SET_MOBILE_NUMBER,
        contactNo: contactNumber
    }
}

export const setUserEmail = (email) => {
    return {
        type: actionTypes.SET_USER_EMAIL,
        email: email
    }
}

export const setPromoCode = (code) => {
    return {
        type: actionTypes.SET_PROMO_CODE,
        promoCode: code
    }
}


export const setUTMData = (data) => {
    return {
        type: actionTypes.SET_UTM_DATA,
        UTMData: data
    }
}


//Register User
export const registerUser = () => {
    return async (dispatch, getState) => {
        const user = getState().newuser.registerUser
        console.log(user);
        await axiosConfig().post('/QuestUser/RegisterUser', { ...user }).then(response => {
            // dispatch(setQuestCategory(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

// Validate OTP
export const validateOTP = (verificationCode, MobileNumber) => {
    return async (dispatch) => {
        await axiosConfig().get('QuestUser/ValidateOTP', {
            params: {
                OTP: verificationCode,
                MobileNumber: MobileNumber
            }
        }).then(response => {
            // dispatch(setQuestCategory(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

export const requestForOTP = (MobileNumber) => {
    return async (dispatch) => {
        await axiosConfig().get('/QuestUser/SendOTP', {
            params: {
                MobileNumber: MobileNumber
            }
        }).then(response => {
            // dispatch(setQuestCategory(response.data))
        }).catch(function (error) {
            console.log(error);
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}


//VerifyOTP
export const VerifyOTP = (verificationCode, MobileNumber) => {
    return async (dispatch) => {
        await axiosConfig().get('QuestUser/VerifyOTP', {
            params: {
                OTP: verificationCode,
                MobileNumber: MobileNumber
            }
        }).then(response => {
            // dispatch(setQuestCategory(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}
