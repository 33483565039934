import React, { useState, useEffect } from 'react';

//Redux
import { useSelector } from 'react-redux'

import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';

//Custom Components
import Loading from 'components/CustomComponents/Progress/Progress'
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"

// custom style
import useStyles from "assets/jss/components/views/questCategoryStyle";

const Intermediate = (props) => {
    const classes = useStyles();
    const userInfoError = useSelector(state => state.user.error)
    const userAuthError = useSelector(state => state.user.auth_error)

    useEffect(() => {
        // session is invalid
        if (userInfoError || userAuthError) {
            console.log("session is invalid");
            localStorage.clear();
            sessionStorage.clear();
            props.history.push('/');
        }
    }, [userInfoError, userAuthError])

    return (
        <div className='signin-container background-signin'>
            <CssBaseline />
            <Loading open={true} ></Loading>
            {/* <GridContainer className={classes.listGridContainer}>
                <GridItem xs={12} sm={12} md={6} >
                    <div style={{ padding: 30 }} >
                        {['001', '010', '011', '111'].map((v, i) =>
                            <GridContainer className={classes.webTopicCard} key={v}>
                                <GridItem xs={12} sm={12} md={12} className={classes.topicCardGrid} >
                                    <Card className={classes.contentCardStyle}>
                                        <CardContent >
                                            <Skeleton variant="rect" width={100} height={100} />
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <Skeleton variant="text" />
                                                <Skeleton variant="text" />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )}
                    </div>
                </GridItem>
            </GridContainer> */}
        </div>
    )
}

export default Intermediate;

