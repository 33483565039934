import {
    whiteColor,
    primaryColor
} from "assets/jss/material-dashboard-react.js";


const drawerWidth = 240;
const styles = theme => ({
    root: {
        display: 'flex',
    },

    inputStyle: {
        color: 'white',
        padding: 0,
        maxWidth: 40,
        borderBottom: '2px solid #3f51b5'
    },
    pdfControl: {
        flexDirection: "row",
        display: "flex",
        alignItems: 'center',
        color: "white",
        backgroundColor: 'rgba(0,0,0,1)',
        position: 'fixed',
        left:0,
        zIndex: '100',
        width: '100%',
        bottom: 0,
        maxHeight: '50px',
        justifyContent: "center"
    },
    fab: {
        position: 'fixed',
        zIndex: '100',
        bottom: theme.spacing(10),
        right: theme.spacing(4),
      },



});

export default styles;
