
import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    platform: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PLATFORM:
            return {
                platform: action.platform
            }
        default:
            return state;
    }
}

export default reducer;