import * as actionTypes from '../actionTypes'

const initialState = {
    missionsSchedules: {
        scheduleDataResult: []
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MISSIONS_SCHEDULES:
            return {
                ...state,
                missionsSchedules: action.schedule
            }
        default:
            return state;
    }

}


export default reducer