import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";


//----- show Leader Board------------------
const actionShowLeaderBoard = (displayFlag) => {

    return {
        type: actionTypes.SHOW_LEADERBOARD_DIALOG,
        paylod: displayFlag
    }
}

export const showLeaderBoard = (displayFlag,) => {
    return (dispatch, getState) => {
        if (displayFlag) {
            const questId = getState().quest.current_quest.questId
            dispatch(initLeaderBoardData(questId));
        }

        dispatch(actionShowLeaderBoard(displayFlag));
    }
}


//----- fetch Leader Board ------------------
export const fetchLeaderBoardStart = () => {
    return {
        type: actionTypes.FETCH_LEADERBOARD_DATA_START
    }
}

export const setLeaderBoardData = (LeaderBoard) => {
    return {
        type: actionTypes.SET_LEADERBOARD_DATA,
        leaderboard: LeaderBoard
    }
}

export const fetchLeaderBoardFailed = () => {
    return {
        type: actionTypes.FETCH_LEADERBOARD_DATA_FAILED
    }
}

export const initLeaderBoardData = (QuestId) => {
    return dispatch => {
        dispatch(fetchLeaderBoardStart());
        axiosConfig().get('/QuestUser/GetLeaderBoard', {
            params: {
                QuestId: QuestId
            }
        }).then(response => {
            dispatch(setLeaderBoardData(response.data))
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchLeaderBoardFailed())
        });
    }

}


