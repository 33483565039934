import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

// import material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
// import Tooltip from '@material-ui/core/Tooltip';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import material-ui/icons
import Icon from '@material-ui/core/Icon';
import CallIcon from '@material-ui/icons/Call';

// variables
import {
    SHARE_URL_ANDROID,
    SHARE_URL_IOS
} from 'variables/general'

//import style
import styles from "assets/jss/components/customcomponents/mobileHeaderStyle";

const useStyles = makeStyles(styles);


const MuiBottomNavigationAction = (props) => {
    const classes = useStyles();
    const { label, onClick, iconStyle, iconName, ...rest } = props
    return (
        <BottomNavigationAction
            {...rest}
            label={label}
            onClick={() => onClick()}
            icon={<Icon className={iconName} fontSize='small' classes={{ root: iconStyle }} />}
            className={classes.buttonNavigationAction}
            classes={{
                selected: classes.buttonNavigationsSelected,
                label: classes.actionLabel,
                root: classes.bottomNavigationActionRoot
            }}

        />
    )
}



const AdminNavbarLinks = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleClickShare = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.shareContent(`Hello, I discovered the Singhania Quest+ App recently. The app has engauging  Academic and non-academic content. Please download the app from ${SHARE_URL_ANDROID(props.userInfo.userId)} and explore the App.`)
        } else if (typeof shareContent !== 'undefined') {
            window.shareContent(`Hello, I discovered the Singhania Quest+ App recently. The app has engauging  Academic and non-academic content. Please download the app from ${SHARE_URL_IOS()} and explore the App.`)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('shareContent', "Hi Quest");
        }
    }


    return (
        <>
            <AppBar className={classNames(classes.topBar)} >
                <Toolbar className={classes.toolbar} classes={{ gutters: classes.toolbarGutters, regular: classes.muiToolbarRegular }} >
                    <div className={classes.logoDiv}>
                        <img src={props.logo} className={classes.imageStyle} alt="..." />
                    </div>
                    <div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                            <Typography variant="caption" className={classes.userNameText}>
                                {props.loading
                                    ? <Skeleton animation="wave" width={70} height={25} />
                                    : "Hi," + props.userInfo.firstName}
                            </Typography>

                            {props.loading
                                ? <Skeleton animation="wave" variant="circle" width={25} height={25} />
                                : <Avatar
                                    src={props.userInfo.profilePic}
                                    className={classes.smallAvatar}
                                />
                            }
                        </div>

                        <IconButton onClick={() => props.openLeaderBoard()} label="Leader Board" className={classes.iconbqbnseButton}>
                            <Icon fontSize="small" className='icon-bqbnse' classes={{ root: classes.iconRoot }} />
                        </IconButton>

                        <IconButton onClick={() => props.history.push('/home/notification')} label="Notification" className={classes.iconnNotificationsButton}>
                            <Icon fontSize="small" className='icon-ic-notifications-24px' classes={{ root: classes.iconRoot }} />
                        </IconButton>

                        <IconButton onClick={() => { }} label="speed dial" className={classes.iconDialButton}>
                            <a href="tel:+917400044432" style={{ color: 'inherit' }}>
                                <CallIcon fontSize="small" classes={{ root: classes.svgIconRoot }} />
                            </a>
                        </IconButton>

                        <IconButton onClick={handleClickShare} label="speed dial" className={classes.iconButton}>
                            <Icon fontSize="small" className={'icon-ic-share-24px'} classes={{ root: classes.iconRoot }} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <AppBar className={classNames(classes.bottomBar)} >
                <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        showLabels
                        classes={{ root: classes.bottomNavigation }}
                    >
                        <MuiBottomNavigationAction
                            label={"Home"}
                            onClick={() => props.history.push('/home')}
                            iconName={'icon-ic-account-balance-24px'}
                        />
                        <MuiBottomNavigationAction
                            label={"Progress"}
                            onClick={() => props.history.push('/home/dashboard')}
                            iconName={'icon-group-22'}
                            iconStyle={classes.muiIconRoot}
                        />
                        <MuiBottomNavigationAction
                            label={"Live classes"}
                            onClick={() => props.history.push('/home/connect')}
                            iconName={'icon-ic-live-tv-24px'}
                        />
                        <MuiBottomNavigationAction
                            label={"Profile"}
                            onClick={() => props.history.push('/home/profile')}
                            iconName={'icon-ic-camera-front-24px'} />

                        {/*typeof window.Android !== 'undefined' && <MuiBottomNavigationAction
                            label={"QueryBox"}
                            onClick={() => props.history.push('/home/querybox')}
                            iconName={'icon-ic-speaker-notes-24px'} />
                    */}
                    </BottomNavigation>
                </Toolbar>
            </AppBar>
        </>


    );
}

AdminNavbarLinks.propTypes = {
    classes: PropTypes.object
};
export default withRouter(AdminNavbarLinks);