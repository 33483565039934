import React, { useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//Lottie
import Lottie from 'react-lottie';
import infoData from 'assets/lotties/info';
import errorData from 'assets/lotties/error-cone';
import successData from 'assets/lotties/success-message';
import likeData from 'assets/lotties/like';

import styles from "assets/jss/components/customcomponents/alertStyle.js";

const useStyles = makeStyles(styles);



const AlertDialog = (props) => {
    const classes = useStyles();
    const { className, action, children, open, title, titleStyle, type, ContentText, ContentTextStyle, cancelable, ...rest } = props;
    const [animation, setAnimationData] = React.useState("");



    const alrClasses = classNames({
        [classes[type]]: type,
        [className]: className
    });

    useEffect(() => {
        switch (type) {
            case "info":
                setAnimationData(infoData)
                break;
            case "danger":
            case 'error':
                setAnimationData(errorData)
                break;
            case "success":
                setAnimationData(successData)
                break;
            case "like":
                setAnimationData(likeData)
                break
            default:
                setAnimationData(infoData)
        }
    }, [type])

    return (
        <Dialog
            classes={{ paperScrollPaper: alrClasses }}
            open={open}
            onClose={cancelable}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...rest}
        >
            <DialogTitle id="alert-dialog-title" style={titleStyle}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animation,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={50}
                    width={50}
                />
                {title}

            </DialogTitle>
            {ContentText &&
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={ContentTextStyle}>
                        {ContentText}
                    </DialogContentText>
                </DialogContent>
            }
            {props.children}
            {action ?
                <DialogActions>
                    {action.map((currentAction, index) =>
                        <Button onClick={currentAction.onPress} color="primary" key={"DIALOG121" + index}>
                            {currentAction.text}
                        </Button>
                    )}
                </DialogActions>
                : null
            }
        </Dialog>
    );
}

AlertDialog.defaultProps = {
    cancelable: false,
    ContentTextStyle: {},
    titleStyle: {},
    type: "info"
}
AlertDialog.propTypes = {
    type: PropTypes.oneOf([
        "info",
        "success",
        "warning",
        "danger",
        'error',
        'like'
    ]),
    open: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.node,
    titleStyle: PropTypes.object,
    ContentText: PropTypes.node,
    ContentTextStyle: PropTypes.object,
    action: PropTypes.any,
    cancelable: PropTypes.func
};

export default AlertDialog;