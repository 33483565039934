import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    registerUser: {
        "userName": "",
        "password": "",
        "firstName": "",
        "lastName": "",
        "emailId": "",
        "contactNo": "",
        "schoolName": "",
        "cityName": "",
        "boardId": "",
        "gradeId": "",
        "promoCode": "",
        "UTMData": ""
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_REGISTER_USER:
            return {
                ...state,
                registerUser: { ...initialState.registerUser }
            }
        case actionTypes.SET_FLNAME:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    firstName: action.firstName,
                    lastName: action.lastName
                }
            }
        case actionTypes.SET_USERNAME_PASSWORD:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    userName: action.userName,
                    emailId: action.userName,
                    password: action.password
                }
            }
        case actionTypes.SET_SCHOOLNAME_CITY:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    schoolName: action.schoolName,
                    cityName: action.cityName
                }
            }
        case actionTypes.SET_MOBILE_NUMBER:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    contactNo: action.contactNo
                }
            }

        case actionTypes.SET_PROMO_CODE:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    promoCode: action.promoCode
                }
            }

        case actionTypes.SET_UTM_DATA:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    UTMData: action.UTMData
                }
            }


        default:
            return state;
    }

}


export default reducer