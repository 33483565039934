import React from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
//@material-ui/core
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// core components
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";

//@material-ui/icons
import AttachmentIcon from '@material-ui/icons/Attachment';
// custom style
import useStyles from "./styles/messagesCardStyle";

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        <GridContainer className={classes.gridContainerStyle}>
            <GridItem xs={12} sm={12} md={12} className={classes.gridItemStyle}>
                {['SK0011', 'DK0101', 'PL0111', 'LK1111'].map((v, i) =>
                    <Card className={classes.cardStyle} key={v}>
                        <CardContent>
                            <Skeleton animation="wave" variant="rect" />
                            <div style={{ height: 5 }}></div>
                            <Skeleton animation="wave" variant="rect" height={30} />
                        </CardContent>

                    </Card>
                )}
            </GridItem>
        </GridContainer>
    </React.Fragment>
    )
}



const MessagesCard = (props) => {
    const classes = useStyles();
    const { loading, data, noDataMessage, onClickRow } = props;

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }

    return (
        <React.Fragment>
            <GridContainer className={classes.gridContainerStyle}>
                <GridItem xs={12} sm={12} md={12} className={classes.gridItemStyle}>
                    {data.length ?
                        data.map((message, idex) => (
                            <Card key={idex + "CARD" + message.id} className={classes.cardStyle}>
                                <CardActionArea onClick={() => onClickRow ? onClickRow(message) : null}>
                                    <CardContent className={classes.cardContent}>
                                        <div style={{ display: 'flex', flexDirection: "column" }}>
                                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                                <div style={{ width: '100%' }}>
                                                    <Typography variant="caption" style={{ fontWeight: '500' }}  >
                                                        {message.Date}
                                                    </Typography>
                                                    <Typography variant="subtitle2" className={classes.senderName} gutterBottom>
                                                        {message.missionDisplayName}
                                                    </Typography>
                                                </div>
                                                {message.attachmentList ?
                                                    <AttachmentIcon color='secondary' />
                                                    : null
                                                }
                                            </div>


                                            <Typography variant="subtitle2" className={classes.subject} gutterBottom>
                                                {message.subject}
                                            </Typography>

                                            <Typography variant="subtitle2" className={classes.messageText} >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: message.messageBody
                                                    }}
                                                ></div>
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))
                        : <Card className={classes.cardStyle}>
                            <CardContent style={{ justifyContent: "center", display: 'flex' }}>
                                <Typography variant="body1" color="textSecondary">
                                    {noDataMessage}
                                </Typography>

                            </CardContent>
                        </Card>
                    }
                </GridItem>
            </GridContainer>
        </React.Fragment>
    );
}

MessagesCard.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    noDataMessage: PropTypes.string,
    onClickRow: PropTypes.func
};

export default MessagesCard;