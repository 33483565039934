import React, { useRef, useState, useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
//validations
import { checkNull, checkMobileNumber } from 'util/validations'
//commonfunctions
import { clevertapEvents } from "util/commonfunctions";
// import axios
// import axiosConfig from "util/axios";

// import material component
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
//import Custom Components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import CardHeader from "components/CustomComponents/Card/CardHeader.js";
import { TextInput } from "components/CustomComponents/Inputs/Inputs";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// Views
import VerifyMobileOTP from 'components/Views/SignUp/VerifyMobileOTP';

// custom style
import useStyles from "assets/jss/components/views/signupStyle";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MobileNumber = (props) => {
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.newuser.registerUser)

  const ref = React.createRef();

  const classes = useStyles();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [OTPDialog, setOTPDialog] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [isMobileValidate, setMobileValidation] = useState(false)
  const [state, setState] = useState({
    contactNumber: userDetails.contactNo,
    errorcontactNumber: "",
  })

  useEffect(() => {
    if (userDetails.contactNo === state.contactNumber && userDetails.contactNo !== "")
      setMobileValidation(true)
  }, [dispatch])

  const onSendOTP = async () => {

    let validationObject = {};
    checkNull("contactNumber", state.contactNumber, "Mobile number is required", validationObject);
    checkMobileNumber("contactNumber", state.contactNumber, "Mobile number is not valid", validationObject);
    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorcontactNumber: "",
        ...validationObject
      });
    } else {
      setState({ ...state, errorcontactNumber: "" });
      await dispatch(questActions.setContactNumber(state.contactNumber));
      props.handleLoading(true);

      //clevertap
      clevertapEvents('OtpRequest',"{\"Mobilenumber\":\"+91" + state.contactNumber + "\"}");

      dispatch(questActions.requestForOTP(state.contactNumber)).then(() => {
        setOTPDialog(true)
        props.handleLoading(false);
      }).catch(error => {
        props.handleLoading(false);
        setAlertType("danger")
        setAlertMessage(error.message)
      });
    }
  }


  // const onNext = () => {
  //   // props.handleNext()
  //   if (isMobileValidate) {
  //     props.handleLoading(true);
  //     axiosConfig().post('/QuestUser/RegisterUser', { ...userDetails }).then(response => {
  //       if (response.data.error) {
  //         props.handleLoading(false);
  //         setAlertType("danger")
  //         setAlertMessage(response.data.error)
  //       } else {
  //         setAlertType("success")
  //         setAlertMessage("Registration completed successfully!")
  //         props.handleLoading(false);
  //         //props.handleNext()
  //       }
  //     }).catch(function (error) {
  //       console.log(error);
  //     });
  //   } else {
  //     setState({
  //       ...state,
  //       errorcontactNumber: "Please validate mobile number",
  //     });
  //   }
  // }
  const onNext = () => {
    if (isMobileValidate) {
      props.handleNext()
    } else {
      setState({
        ...state,
        errorcontactNumber: "Please validate mobile number",
      });
    }
  }




  const verifyMobile = (isVerify) => {
    setMobileValidation(isVerify)
    setSnackbarMessage("Your mobile number is verified")
  }

  const onCloseAlert = () => {
    switch (alertType) {
      case "danger":
        setAlertMessage("")
        break;
      case "success":
        setAlertMessage("")
        props.handleNext()
        break;
      default:
        setAlertMessage("")
    }
  }

  return (
    <GridContainer className={classes.stepContent}>

      {/*-----Dialog----- */}
      <Dialog open={OTPDialog} maxWidth={"sm"} fullScreen={fullScreen} TransitionComponent={Transition} >
        <VerifyMobileOTP
          mobileNumber={state.contactNumber}
          onClose={setOTPDialog}
          onValidate={verifyMobile}
        />
      </Dialog>

      <AlertDialog
        maxWidth="xs"
        open={alertMessage ? true : false}
        type={alertType}
        ContentText={alertMessage}
        ContentTextStyle={{ color: 'black', marginBottom: 0 }}
        title={null}
        titleStyle={{ paddingBottom: 0 }}
        cancelable={() => { }}
        action={[{ text: 'OK', onPress: () => onCloseAlert() }
        ]}
      />


      <Snackbar open={snackbarMessage ? true : false} autoHideDuration={6000} onClose={() => setSnackbarMessage("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarMessage("")} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        {/* <Paper > */}
        <CardHeader
          titleClassName={classes.titleHeader}
          className={isMobileValidate ? classes.successHeader : classes.cardHeader}
          title={isMobileValidate ? 'Your mobile number is verified' : 'Please enter Mobile number'}
        />

        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              ref={ref}
              inputRef={input => input && input.focus()}
              disabled={isMobileValidate ? true : false}
              type="number"
              className={classes.number}
              id="MOBILE_NUMBER"
              autoFocus={true}
              label="Mobile Number"
              value={state.contactNumber}
              errorText={state.errorcontactNumber}
              onChange={(e) => setState({ ...state, contactNumber: e.target.value })}
              InputProps={{
                startAdornment: <InputAdornment position="start">+91</InputAdornment>,
              }}
              onInput={(e) => {
                e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ""
              }}
            />
            {isMobileValidate ?
              <div style={{ display: 'flex' }}>
                <Typography variant="subtitle2">
                  <samp className={classes.hyperText}
                    onClick={() => {
                      setMobileValidation(false)
                    }} >
                    Edit
                  </samp>
                  {" mobile number."}
                </Typography>
              </div>
              : null
            }


            <div style={{ marginTop: 10, visibility: state.contactNumber.length === 10 && !isMobileValidate ? 'visible' : 'hidden' }}>
              <Button fullWidth variant="contained" size="sm" color="success" onClick={onSendOTP}>
                GET OTP
             </Button>
            </div>

          </GridItem>

        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
        <div className={classes.actionsContainer}>
          <Button
            size="normal"
            // disabled={props.activeStep === 0}
            onClick={props.handleBack}
            className={classes.button}
          >
            Back
            </Button>

          <Button
            size="normal"
            variant="contained"
            color="primary"
            onClick={onNext}
            className={classes.button}
          >
            {props.activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </GridItem>

    </GridContainer>

  )
}

export default MobileNumber;