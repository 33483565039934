import React from 'react';
// nodejs library to set properties for components
import moment from 'moment'
import PropTypes from "prop-types";
//@material-ui/core

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// core components
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Button from "components/CustomComponents/CustomButtons/Button.js";

//variables
import {
    SHARE_URL_ANDROID,
    SHARE_URL_IOS
} from "variables/general.js";
// custom style
import useStyles from "./styles/messagesCardStyle";


const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        {['SK0011', 'DK0101', 'PL0111', 'LK1111'].map((v, i) =>
            <Card className={classes.cardStyle} key={v}>
                <CardContent>
                    <Skeleton animation="wave" variant="rect" />
                    <div style={{ height: 5 }}></div>
                    <Skeleton animation="wave" variant="rect" height={30} />
                </CardContent>

            </Card>
        )}
    </React.Fragment>
    )
}




const JoinButton = ({ data, datetime, ...rest }) => {
    const onClickJoin = () => {
        if (typeof window.Android === 'undefined' && typeof openNewUrl === 'undefined' && typeof window.openNewUrl === 'undefined') {
            window.open(data.joinLink, "_blank")
        } else {
            if (typeof window.Android !== 'undefined') {
                if (typeof window.Android.openZoomUrl !== 'undefined') {
                    window.Android.openZoomUrl(data.appJoinLink)
                }
            } else if (typeof window.flutter_inappwebview !== 'undefined') {
                if (typeof window.flutter_inappwebview.openZoomUrl !== 'undefined') {
                    window.flutter_inappwebview.callHandler('openZoomUrl', data.appJoinLink, '');
                }
            } else {
                if (typeof window.openZoomUrl !== 'undefined') {
                    window.openZoomUrl(data.appJoinLink)
                }
            }
        }
    }
    const diff = moment(data.startTime).diff(moment(datetime), 'minutes')

    if (diff > 5) {
        return (<Button size='sm' color="facebook" {...rest}>Upcoming</Button>)
    }

    if (diff > 0 && (diff <= 5) || diff > -(data.duration)) {
        return <Button size='sm' onClick={onClickJoin} color="facebook" {...rest}>Join</Button>
    }

    return (<Button size='sm' color="warning" {...rest}>View recording</Button>)
}



const MessagesCard = (props) => {
    const classes = useStyles();
    const { loading, data, noDataMessage, currentdatetime } = props;

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }

    // handle Click Share
    const handleClickShare = (data) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.shareContent(`Hi, I'm planning to join an online session regarding ${data.scheduleName} on ${moment(data.startTime).format('DD MMM YYYY')} at ${moment(data.startTime).format('hh:mm:ss a')}. I request you to join this session with me by download the Singhania Quest+ app ${SHARE_URL_ANDROID(props.userInfo.userId)}.`)
        } else if (typeof shareContent !== 'undefined') {
            window.shareContent(`Hi, I'm planning to join an online session regarding ${data.scheduleName} on ${moment(data.startTime).format('DD MMM YYYY')} at ${moment(data.startTime).format('hh:mm:ss a')}. I request you to join this session with me by download the Singhania Quest+ app ${SHARE_URL_IOS()}.`)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('shareContent', "Hi Quest");
        }
    }

    return (
        <React.Fragment>
            {data.length ?
                data.map((data, idex) => (
                    <Card key={idex + "CARD"} className={classes.cardStyle}>

                        <CardContent className={classes.cardContent}>

                            <GridContainer className={classes.gridContainer}>
                                <GridItem xs={3} sm={2} md={2} className={classes.gridItemStyle}>
                                    <div className={classes.dateContainer}>
                                        <Typography variant="caption" className={classes.subject}>
                                            {`${moment(data.startTime).format('ddd')}`}
                                        </Typography>
                                        <Typography variant="body1" className={classes.subject} style={{ fontWeight: 'bold' }}>
                                            {`${moment(data.startTime).format('DD')}`}
                                        </Typography>
                                        <Typography variant="caption" className={classes.subject}>
                                            {`${moment(data.startTime).format('MMM YYYY')}`}
                                        </Typography>
                                    </div>

                                </GridItem >

                                <GridItem xs={6} sm={8} md={8} className={classes.gridItemStyle}>
                                    <div className={classes.nameConatiner}>
                                        <div >
                                            <Typography variant="caption" className={classes.subject}>
                                                TIME - <space style={{ fontWeight: 'bold' }}> {` ${moment(data.startTime).format('hh:mm:ss a')}`} </space>   ({`${data.duration}min`})
                                            </Typography>
                                        </div>

                                        <div style={{ paddingTop: 5 }}>
                                            <Typography variant="subtitle2" className={classes.subject}>
                                                {data.scheduleName}
                                            </Typography>
                                        </div>
                                    </div>
                                </GridItem>

                                <GridItem xs={3} sm={2} md={2} className={classes.gridItemStyle}>
                                    <div className={classes.buttonContainer}>
                                        <JoinButton data={data} datetime={currentdatetime} className={classes.buttonClass} />
                                        <Button size='sm' color="warning" className={classes.buttonClass} onClick={() => handleClickShare(data)}>Share</Button>
                                    </div>
                                </GridItem>
                            </GridContainer>

                        </CardContent>

                    </Card>
                ))
                : <Card className={classes.cardStyle}>
                    <CardContent style={{ justifyContent: "center", display: 'flex' }}>
                        <Typography variant="body1" color="textSecondary">
                            {noDataMessage}
                        </Typography>

                    </CardContent>
                </Card>
            }
        </React.Fragment>
    );
}

MessagesCard.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    noDataMessage: PropTypes.string,
    onClickRow: PropTypes.func
};

export default MessagesCard;