import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

//----- fetch Change Password ------------------
export const fetchSharedDataStart = () => {
    return {
        type: actionTypes.FETCH_SHARED_DATA_START
    }
}

export const setSharedData = (SharedData) => {
    return {
        type: actionTypes.SET_SHARED_DATA,
        sharedData: SharedData
    }
}

export const fetchSharedDataFailed = () => {
    return {
        type: actionTypes.FETCH_SHARED_DATA_FAILED
    }
}

export const initSharedData = (UserId, QuestId) => {
    return dispatch => {
        dispatch(fetchSharedDataStart());
        axiosConfig().get('/QuestUser/GetSharedData', {
            params: {
                UserId: UserId,
                QuestId: QuestId
            }
        }).then(response => {
            dispatch(setSharedData(response.data))
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchSharedDataFailed())
        });
    }

}



//----- Post Shared Data ------------------
export const postSharedDataStart = () => {
    return {
        type: actionTypes.POST_SHARED_DATA_START
    }
};

export const postSharedDataSuccess = () => {
    return {
        type: actionTypes.POST_SHARED_DATA_SUCCESS
    }
};

export const postSharedDataFailed = () => {
    return {
        type: actionTypes.POST_SHARED_DATA_FAILED
    }
};


export const postSharedData = (formData) => {
    return async (dispatch) => {
        dispatch(postSharedDataStart());
        await axiosConfig().post('QuestUser/PostSharedData', formData)
            .then(response => {
                dispatch(postSharedDataSuccess())
            }).catch((error) => {
                dispatch(postSharedDataFailed())
                if (error.response.data)
                    throw new Error(error.response.data.error)
                throw new Error(error.message);
            });
    }
}