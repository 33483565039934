import {
    dangerColor,
    boxShadow
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        borderRadius: 10,
        margin: 10,
        ...boxShadow,
    },
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },
    senderName: {
        width: '85%',
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    subject: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    messageText: {
        overflow: 'hidden',
        lineHeight: 1.3,
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '2',
        },
        fontSize: '0.875rem',
        fontWeight: '500',
        letterSpacing:' 0.00714em'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 14,
        marginRight: 5
    },
    gridContainerStyle: {
        margin: 0,
        height: '100%',
        overflowY: 'overlay',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            //   height: '60vh',
        },
    },
    gridItemStyle: {
        padding: 0
    },
    cardContent: {
        padding: '8px 16px'
    },
    chip: {
        margin: theme.spacing(0.2),
        borderRadius: 2,
        maxWidth: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '-webkit-fill-available',
        },
    },

}));

export default useStyles;