
import React from 'react';
import classNames from "classnames";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// style
import {
    orangeColor,
    dangerColor,
    successColor
} from "assets/jss/material-dashboard-react.js";


// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex'
    },

    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#1a90ff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    colorPrimary25: {
        color: dangerColor[0],
    },
    colorPrimary50: {
        color: orangeColor[0],
    },
    colorPrimary100: {
        color: successColor[0],
    },

    circle: {
        strokeLinecap: 'round',
    },

}));


function CircularProgressWithLabel(props) {
    const classes = useStylesFacebook();

    const progressClass = classNames({
        [classes.top]: true,
        [classes[`colorPrimary${props.value <= 25 ? '25' : (props.value > 25 && props.value <= 50) ? '50' : '100'}`]]: true,
    });

    return (
        <Box position="relative" display="inline-flex">
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    className={progressClass}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                    variant="static"
                    // disableShrink
                />
            </div>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;