import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 400,
        flexGrow: 1,
        backgroundColor: '#fff !important'
    },
    mobileStepper: {
        flexGrow: 1,
        justifyContent: "center !important",
        background: 'transparent !important'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: '100%',
        display: 'block',
        overflow: 'hidden',
        width: '100%',
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    swipeableViewsContainer: {
        paddingTop: 20
    }, buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        // textAlign: 'center',
        alignItems: 'center',
        padding: '0 15px',
    },
    topicsTitle: {
        width: '85%',
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '2',
        },
    },
    accordionDiv: {
        overflowY: 'overlay',
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
    },
    accordionClass: {
        // backgroundColor: '#F2F8FF',
        margin: '10px 10px !important',
        borderRadius: '5px !important',
        "&:before": {
            height: "0px !important"
        }
    },
    accordionSummaryClass: {
        padding: '0px 16px 0px 10px'
    },

    accordionSummaryRoot: {
        minHeight: 'unset !important',
        height:50,
        [theme.breakpoints.down('sm')]: {
            height:30
        },
       
    },

    accordionDetails:{
        padding: '0px 5px 1px',
        display: 'block',
        marginTop:-8
    },

    heading: {
        height: 'auto',
        lineHeight: 1.1,
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',   
            // lineHeight: '1.66',
            letterSpacing: '0.03333em'
        }

    },
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },




}));

export default useStyles;