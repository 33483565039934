import React, { useState, useEffect } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//Views
import VerifyOTP from "components/Views/VerifyOTP/VerifyOTP";

const CouponCode = (props) => {
    const { username, onClose, onValidate, title } = props
    const dispatch = useDispatch();
    const verification_status = useSelector(state => state.resetpassword.OTPVerificationStatus)

    const [alertType, setAlertType] = React.useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(verification_status).length)
            if (verification_status.isSuccess) {
                onClose(false)
                onValidate(true, verification_status.message)
            } else {
                onValidate(false, verification_status.message)
            }
    }, [verification_status])

    const onValidateOTP = async (verificationCode) => {
        dispatch(questActions.validateUernameOTP(username, verificationCode)).catch(error => {
            setAlertType("error")
            setSnackbarMessage(error.message)
        });
    }

    const resendOTP = async () => {
        setLoading(true)
        dispatch(questActions.verifyUser(username)).then(() => {
            setLoading(false)
            setAlertType("success")
            setSnackbarMessage("OTP was resent successfully!")
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <React.Fragment>
            <VerifyOTP
                title={title}
                resendOTP={resendOTP}
                onValidateOTP={onValidateOTP}
                onClose={onClose}
                loading={loading}
                snackbarseverity={alertType}
                snackbarMessage={snackbarMessage}
                setSnackbarMessage={setSnackbarMessage}
                username={username}
            />
        </React.Fragment>
    )
}

export default CouponCode;