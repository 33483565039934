import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

//commonfunctions
import { clevertapEvents } from "util/commonfunctions";

//import for initialize Content List &  User Progress
import { initContentList } from "store/actions/contentList"
import { initUserInfo } from "store/actions/userInfo"
import { fetchCartItems } from "store/actions/questStore";

export const fetchUserQuestListStart = () => {
    return {
        type: actionTypes.FETCH_USERQUEST_LIST_START
    }
}


export const setUserQuestList = (USER_QUEST) => {
    return {
        type: actionTypes.SET_USERQUEST_LIST,
        userquest: USER_QUEST
    }
}

export const fetchrQuestListFailed = () => {
    return {
        type: actionTypes.FETCH_USERQUEST_LIST_FAILED
    }
}

// fetch User Quest List with Content List
export const initUserQuestList = (isInitCurrentQuestAndContentList = true) => {
    return async (dispatch, getState) => {
        const userInfo = getState().user.userinfo
        dispatch(fetchUserQuestListStart())
        await axiosConfig().get('/QuestUser/GetUserQuest', {
            params: {
                userId: userInfo.userId,
            }
        }).then(async response => {
            dispatch(setUserQuestList(response.data))
            if (isInitCurrentQuestAndContentList)
                if (response.data.questList.length) {
                    if (userInfo.lastQuestAccessed === undefined) { //&& userInfo.showDashboard === false
                        await dispatch(setCurrentQuest(response.data.questList[0]))
                        await dispatch(initContentList(response.data.questList[0].questId)).then(async () => {
                            await dispatch(initUserInfo()) // refetch user info for get Updated lastQuestAccessed
                        })
                    }
                    else {
                        dispatch(setCurrentQuest(response.data.questList.filter(op => op.questId === userInfo.lastQuestAccessed).reduce((acc, cv) => { return acc = cv }, {})))
                        dispatch(initContentList(userInfo.lastQuestAccessed))
                    }
                }

        }).catch(function (error) {
            console.log(error);
            dispatch(fetchrQuestListFailed())
        });
    }
}

// fetch User Quest List without Content List
export const fetchUserQuestList = (userId) => {
    return async dispatch => {
        dispatch(fetchUserQuestListStart())
        await axiosConfig().get('/QuestUser/GetUserQuest', {
            params: {
                userId: userId,
            }
        }).then(response => {
            dispatch(setUserQuestList(response.data))
        })
            .catch(function (error) {
                dispatch(fetchrQuestListFailed())
            });
    }
}

//set Current Quest
export const setCurrentQuest = (CurrentQuest) => {
    return {
        type: actionTypes.SET_CURRENT_QUEST,
        currentQuest: CurrentQuest
    }
}

//set Current Quest by ID
export const setCurrentQuestByID = (QuestID) => {
    return {
        type: actionTypes.SET_CURRENT_QUEST_BY_ID,
        questID: QuestID
    }
}

//------ ---------------------Quest Category----- -----------------------------------------
export const setQuestCategory = (quest_category) => {
    return {
        type: actionTypes.SET_QUESTCATEGORY,
        questCategory: quest_category
    }
}

export const fetchQuestCategory = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/GetQuestCategory', {
            params: {
                UserId: UserId
            }
        }).then(async response => {
            await dispatch(setQuestCategory(response.data))
            await dispatch(fetchCartItems()) // to fetch Cart Items
        }).catch(function (error) {
            console.log(error);
            // dispatch(fetchSharedDataFailed())
        });
    }
}

//Assign Quest
export const postAssignQuest = (QuestId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/AssignQuest', {
            params: {
                UserId: UserId,
                QuestId: QuestId
            }
        }).then(async response => {
            await dispatch(fetchUserQuestList(UserId))
        }).catch(function (error) {
            throw new Error(error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

//Assign Trial Quest 
export const postAssignTrialQuest = (QuestId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        const lastQuestAccessed = getState().user.userinfo.lastQuestAccessed
        await axiosConfig().get('/QuestUser/AssignTrialQuest', {
            params: {
                UserId: UserId,
                QuestId: QuestId
            }
        }).then(async response => {
            await dispatch(fetchUserQuestList(UserId))
        }).catch(error => {
            if (lastQuestAccessed) {
                dispatch(setCurrentQuestByID(lastQuestAccessed))
                dispatch(initContentList(lastQuestAccessed))
            }
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}




//Set Quest Details
export const setQuestDetails = (QuestDetails) => {
    return {
        type: actionTypes.SET_QUEST_DETAILS,
        questDetails: QuestDetails
    }
}

export const fetchQuestDetails = (QuestId, isSetCurrentQuest = false) => {
    return async dispatch => {
        await axiosConfig().get('/QuestContent/GetQuest', {
            params: {
                QuestId: QuestId
            }
        }).then(response => {
            if (isSetCurrentQuest) {
                dispatch(setCurrentQuest(response.data))
                //clevertap
                const eventData = JSON.stringify({
                    'QuestId': response.data.questId,
                    'QuestName': response.data.questName
                });
                clevertapEvents('QuestSelected', eventData);
            }

            dispatch(setQuestDetails(response.data))
        }).catch(function (error) {
            if (error.response.data) {
                throw new Error(error.response.data.error)
            }
            throw new Error(error.message)
        });
    }
}



//------------------------ Quest Products------------------------
export const setQuestProducts = (product_category) => {
    return {
        type: actionTypes.SET_QUESTPRODUCTS,
        productCategory: product_category
    }
}

export const fetchProductCategory = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/GetProductCategory', {
            params: {
                UserId: UserId
            }
        }).then(async response => {
            await dispatch(setQuestProducts(response.data))
            await dispatch(fetchCartItems()) // to fetch Cart Items
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error)
        });
    }
}


//fetch Product Details
export const setProductDetails = (ProductDetails) => {
    return {
        type: actionTypes.SET_PRODUCT_DETAILS,
        productDetails: ProductDetails
    }
}

export const fetchProductDetails = (ProductId) => {
    return async dispatch => {
        await axiosConfig().get('/QuestContent/GetProduct', {
            params: {
                ProductId: ProductId
            }
        }).then(response => {
            dispatch(setProductDetails(response.data))
        }).catch(function (error) {
            console.log(error);
            // dispatch(fetchSharedDataFailed())
        });
    }
}

//AssignFreeProduct
export const assignFreeProduct = (ProductId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/AssignFreeProduct', {
            params: {
                UserId: UserId,
                ProductId: ProductId
            }
        }).then(async response => {
            await dispatch(fetchUserQuestList(UserId))
        }).catch(function (error) {
            throw new Error(error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

///AssignTrialProduct
export const assignTrialProduct = (ProductId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        const lastQuestAccessed = getState().user.userinfo.lastQuestAccessed
        await axiosConfig().get('/QuestUser/AssignTrialProduct', {
            params: {
                UserId: UserId,
                ProductId: ProductId
            }
        }).then(async response => {
            await dispatch(fetchUserQuestList(UserId))
        }).catch(error => {
            if (lastQuestAccessed) {
                dispatch(setCurrentQuestByID(lastQuestAccessed))
                dispatch(initContentList(lastQuestAccessed))
            }
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

