import {
    dangerColor,
    boxShadow
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        marginBottom: "15px",
        marginTop: "10px",
        borderRadius: 10,
        // borderBottomStyle: 'inset',
        ...boxShadow,
        // padding: 10
    },


    subject: {
        // display: 'flex',
        // fontWeight: 'bold',
        lineHeight: 1.2,
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '2',
        },
    },

    gridContainer: {
        margin: 0
    },
    gridItemStyle: {
        padding: 0
    },

    gridItemButton: {
        // padding: 0,
    },


    cardContent: {
        padding: '0px 0px',
        paddingBottom: '0 !important'
    },

    buttonContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    buttonClass: {
        width: '100%',
        margin: 0,
        height: '100%',
        borderRadius: 0
    },
    dateContainer: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center'
    },
    nameConatiner: {
        display: 'flex',
        flexDirection: "column",
        padding: '5px 0px',
        justifyContent:'center',
        height:'100%'
    }
}));

export default useStyles;