import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'

//Custom Components
import MessagesCard from "../../components/MessageComponents/MessagesCard";
import OperationCard from "../../components/OperationCards/OperationCard";

//Style
import useStyles from './styles.js'

const Inbox = (props) => {
    const { loading, currentdatetime, operations } = props
    const dispatch = useDispatch();
    let history = useHistory();
    const classes = useStyles();
    const schedules = useSelector(state => state.schedules.missionsSchedules.scheduleDataResult)


    const onClickOperation = (operation) => {
        dispatch(questActions.setCurrentOperation(operation))
        history.push({ pathname: '/topics' })
    }


    return (
        <React.Fragment>
            <MessagesCard
                data={schedules}
                onClickRow={() => { }}
                loading={loading}
                noDataMessage={"No data available"}
                currentdatetime={currentdatetime}
            />

            <OperationCard
                data={operations}
                onClick={onClickOperation}
            />

        </React.Fragment>
    )
}

export default Inbox;