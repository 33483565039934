import * as actionTypes from '../actions/actionTypes'

const initialState = {
    inbox_messages: [],
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        //------ Messages  ----------
        case actionTypes.SET_QUERY_DATA:
            return {
                ...state,
                inbox_messages: action.inbox,
                loading: false,
           }
        case actionTypes.FETCH_QUERY_LOADING:
            return {
                ...state,
                loading: action.status,
            }


        default:
            return state;
    }

}


export default reducer