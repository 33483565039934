import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  addIcon: {
    // marginRight: theme.spacing(1),
  },
  mainContainer: {
    transform: 'translate(0%, 0px)',
    // padding: 20,
    borderRadius: 5,
    // maxHeight: '78.5vh',
    height:'78.5vh',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 100px)',
      borderRadius: 0,
    },
  }
}));

export default useStyles;