import React, { useEffect, useState } from "react";
//Redux
import { connect } from 'react-redux'

//import reducer 
import * as questActions from 'store/actions/index'

//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { green } from '@material-ui/core/colors';

import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import CustomMarkdown from 'components/CustomComponents/CustMarkdown/CustMarkdown'
import Progress from 'components/CustomComponents/Progress/Progress'


// styles
import Styles from "assets/jss/components/views/assessmentStyle";
const useStyles = makeStyles(Styles);


const QuestionAnswers = (props) => {
    const classes = useStyles();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (props.reviewe) {
                clearInterval(myInterval)
            } else {
                if (seconds < 60) {
                    setSeconds(seconds + 1);
                }
                if (seconds === 60) {
                    setMinutes(minutes + 1);
                    setSeconds(0);
                }
                props.updateAnswerDuration(1, props.questionId)
            }
        }, 1000)

        return () => {
            clearInterval(myInterval);
        };
    });

    const handleChange = async (value) => {
        setLoading(true)
        await props.updateQuestioAnswers(value, props.questionId)
        setLoading(false)
    };

    const CORRECT_ANSWER = props.answer.split(",").sort()
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Typography className='noselect'>
                        <CustomMarkdown   >
                            {props.questionText}
                        </CustomMarkdown>
                    </Typography>
                </GridItem>
            </GridContainer>

            <List >
                {/*----- Spinner---- */}
                {loading ? <Progress color="secondary" open={loading} /> : null}

                {props.options.optionItems.map(option =>
                    <React.Fragment key={option.id} >
                        {option.ischecked && props.reviewe
                            ? <Typography variant="caption" component="h6" className={classes.yourAnswerText}>
                                Your Answer
                            </Typography>
                            : null
                        }
                        <ListItem
                            disabled={props.reviewe}
                            className={classes.ansListItemStyle}
                            classes={{
                                disabled: classes.disabledListItem,
                                container: classes.listItemContainer,
                            }}
                            style={{ backgroundColor: props.reviewe ? (CORRECT_ANSWER.includes(option.id) || option.ischecked ? "aliceblue" : "") : "" }}
                            key={"option" + option.id} role={undefined} dense button
                            onClick={() => handleChange(option.id)}
                        >
                            <ListItemIcon>
                                {props.questionTypeCode === "QT01" || props.questionTypeCode === "QT03"
                                    ? <Radio checked={option.ischecked} color="primary" />
                                    : props.questionTypeCode === "QT02" ?
                                        <Checkbox checked={option.ischecked} color="primary" />
                                        : null
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={<CustomMarkdown>{option.value}</CustomMarkdown>}
                            />

                            {props.reviewe
                                ? CORRECT_ANSWER.includes(option.id)
                                    ? <ListItemSecondaryAction classes={{root:classes.secondaryAction}}>
                                        <div style={{display:'flex',justifyContent:'flex-end',flexDirection:'column',alignItems:'flex-end',paddingTop:15}}>
                                            <CheckCircleIcon style={{ color: green[500] }} />
                                            <Typography variant="caption" className={classes.correctAnswerText} >
                                                Correct Answer
                                            </Typography>
                                        </div>

                                    </ListItemSecondaryAction>
                                    : null
                                : null
                            }

                        </ListItem>
                        {/* {props.reviewe
                            ? CORRECT_ANSWER.includes(option.id)
                                ? <Typography variant="caption" className={classes.correctAnswerText} >
                                    Correct Answer
                                    </Typography>
                                : null
                            : null
                        } */}
                    </React.Fragment>
                )}

            </List>

            {(props.reviewe && props.explanation) && <div className='noselect'>
                <Typography className={classes.explanationHeaderText}>
                    Explanation :
                </Typography>
                <Typography>
                    <CustomMarkdown   >
                        {props.explanation}
                    </CustomMarkdown>
                </Typography>
            </div>}

        </div>
    )
}




const mapDispatchToProps = dispatch => {
    return {
        updateQuestioAnswers: (answer, questionId) => dispatch(questActions.updateQuestioAnswer(answer, questionId)),
        updateAnswerDuration: (duration, questionId) => dispatch(questActions.updateAnswerDuration(duration, questionId)),

    }
}


export default connect(null, mapDispatchToProps)(QuestionAnswers);
