import * as actionTypes from './actionTypes';
import { serverURL } from 'variables/ServerURL'
import axiosConfig from "util/axios";

export const fetchBoardGrade = () => {
    return async dispatch => {

        await axiosConfig().get('QuestContent/GetBoardGrade',).then(async response => {
            dispatch({
                type: actionTypes.SET_BOARD_GRADE,
                payload: response.data
            });
        }).catch(function (error) {
            throw new Error(error)
        });

        // try {
        //     const response = await fetch(serverURL+"QuestContent/GetBoardGrade ")
        //     if (!response.ok)
        //         throw new Error('Something went wrong!');
        //     else {
        //         const resData = await response.json();
        //         dispatch({
        //             type: actionTypes.SET_BOARD_GRADE,
        //             payload: resData
        //         });
        //     }
        // } catch (error) {
        //     throw error;
        // }
    }
}