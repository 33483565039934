import React from "react";

import ProductDetails from "./ProductDetails";
import QuestDetails from "./QuestDetails";

const Details = (props) => {
    console.log(props);


    let Content = <ProductDetails {...props}/>;
    if (Object.keys(props.data).includes("questId")) {
        Content = <QuestDetails {...props}/>
        }

    return (
        <React.Fragment>
            {Content}
        </React.Fragment>
    );
}





export default Details;
