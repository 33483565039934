
import React from 'react';


//Custom Components
import SwipeableView from "components/CustomComponents/SwipeableView/SwipeableView";
import SwipeCard from "components/Views/QuestProducts/SwipeableViews/SwipeCard";
const AutoPlaySwipeableViews = (props) => {
    return (
        <SwipeableView index={props.activeStep} onChangeIndex={props.stepChange} >
            {props.topics.map((step, index) => (
                <div key={step.productId} style={{ width: '100%' }}>
                     <SwipeCard
                        name={step.productName}
                        image={step.productIcon}
                        data={step}
                        {...props}
                    />
                </div >
            ))
            }
        </SwipeableView >
    )
};


export default AutoPlaySwipeableViews;