import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

// import for initialize User Quest List
import { initUserQuestList } from 'store/actions/userQuestList'

export const fetchUserInfoStart = () => {
    return {
        type: actionTypes.FETCH_USERINFO_START
    }
}

export const setUserInfo = (userInfo) => {
    return {
        type: actionTypes.SET_USERINFO,
        userinfo: userInfo
    }
}

export const fetchUserInfoFailed = () => {
    return {
        type: actionTypes.FETCH_USERINFO_FAILED
    }
}

export const initUserInfo = () => {
    return async dispatch => {
        await axiosConfig().get('/QuestUser/GetCurrentUser').then(async response => {
            await dispatch(setUserInfo(response.data))
        }).catch(error => {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}


// ---upload profile picture-----
export const uploadProfilePicture = (profilePic) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().post('/QuestUser/UploadProfilePic', {
            "userId": UserId,
            "profilePic": profilePic
        }).then(async response => {
            await dispatch(setProfilePic(profilePic))
        }).catch(function (error) {
            console.log(error);
        });
    }
}

//----- Set Profile Pic ------------------
export const setProfilePic = (ProfilePic) => {
    return {
        type: actionTypes.SET_PROFILE_PICTURE,
        profilePic: ProfilePic
    }
}

//----- Post Change Password ------------------
export const postChangePasswordStart = () => {
    return {
        type: actionTypes.POST_CHANGE_PASSWORD_START
    }
};

export const postChangePasswordSuccess = () => {
    return {
        type: actionTypes.POST_CHANGE_PASSWORD_SUCCESS
    }
};

export const postChangePasswordFailed = () => {
    return {
        type: actionTypes.POST_CHANGE_PASSWORD_FAILED
    }
};


export const postChangePassword = (oldPassword, newPassword) => {
    return dispatch => {
        dispatch(postChangePasswordStart());
        axiosConfig().get('/QuestUser/ChangePassword', {
            params: {
                oldPassword: oldPassword,
                newPassword: newPassword
            }
        })
            .then(response => {
                dispatch(postChangePasswordSuccess())
            }).catch((error) => {
                console.log(error);
                dispatch(postChangePasswordFailed())
            });
    }
}


///----- Post Change Password ------------------
export const logoutUser = () => dispatch => {
    dispatch({
        type: 'LOG_OUT'
    })
}

//---setAuthError----
export const setAuthError = (auth) => {
    return {
        type: actionTypes.AUTH_FAILED,
        auth: auth
    }
};

//---------Authenticate User with User Info and User Quest List------------------------
export const authenticateUser = (userName, password) => {
    return async (dispatch, getState) => {
        await axiosConfig().post('/QuestUser/AuthenticateUser', {}, {
            auth: {
                username: userName,
                password: password
            }
        }).then(async response => {
            dispatch(setAuthError(false))
            localStorage.setItem('userName', userName);
            localStorage.setItem('password', password);
            localStorage.setItem('remember', true);
            if (typeof window.Android !== 'undefined') {
                window.Android.onRemenberMeClicked(userName, password, true);
            }
            if (typeof onRemenberMeClicked !== 'undefined') {
                window.onRemenberMeClicked(userName, password, true);
            }
            else if(typeof window.flutter_inappwebview !== 'undefined')
            {
                window.flutter_inappwebview.callHandler('onRemenberMeClicked', userName, password, true);
            }

            sessionStorage.setItem('token', response.data.token);
            await axiosConfig().get('/QuestUser/GetCurrentUser').then(async response => {
                await dispatch(setUserInfo(response.data))
                dispatch(initUserQuestList(false))

            }).catch(function (error) {
                dispatch(fetchUserInfoFailed())
            });

        }).catch((error) => {
            if (error.response) {
                dispatch(setAuthError(true))
                throw new Error(error.response.data.error)
            }
        });
    }
}

// update User Details
export const updateUserDetails = (params) => {
    return async (dispatch, getState) => {
        await axiosConfig().post('/QuestUser/UpdateUser', {
            params: { ...params }
        }).then(response => {
        }).catch((error) => {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

export const setUserType = (userType) => {
    return {
        type: actionTypes.SET_USER_TYPE,
        user_type: userType
    }
}