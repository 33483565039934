import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
//redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

//store
import { store, persistor } from './store/index'

// import './index.css';
import "assets/css/index.css?v=1.8.0";
import * as serviceWorker from './serviceWorker';
import Layout from './components/Layouts/Layout';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { green,blue } from '@material-ui/core/colors';
// import Typography from '@material-ui/core/Typography';

let theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiStepIcon: {
          // Name of the rule
          // root:{
          //   color: '#3f51b5'
          // },
          active: {
            // Some CSS
            color: `${green[500]} !important`,
            boxShadow:'0 0 10px gray',
            borderRadius:50
          },
        },
      },
});
theme = responsiveFontSizes(theme);


ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={createBrowserHistory()}>
                    <Layout />
                </Router>
            </PersistGate>
        </Provider>
    </ThemeProvider>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();
