import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    configs: {
        "tcUrl": "",
        "otpRequired": false,
        "showPromoScreen": false,
        "currentTime": "",
        'supportEmail': ""
    },
    error: false,
    loading: true,
    apptype: "",
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        // -- Fetch app configs
        case actionTypes.FETCH_APPCONFIGS_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_APPCONFIGS:
            return {
                ...state,
                configs: action.appConfigs.configs,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_APPCONFIGS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        case actionTypes.SET_APPTYPE:
            return {
                ...state,
                apptype:action.payload
            }

        default:
            return state;
    }
}

export default reducer