import React, { useState, useEffect } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
import SwipeableViews from 'react-swipeable-views';
//@material-ui/core
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import CardMedia from '@material-ui/core/CardMedia';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

// import material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';

//Custom Components
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Button from "components/CustomComponents/CustomButtons/Button.js";
import SearchInput from "components/CustomComponents/Inputs/SearchInput/SearchInput";

// Views
import QuestDetails from 'components/Views/QuestCategory/QuestDetails'
import SigninComponent from 'components/Views/SignIn/SigninSpectrum/SigninComponent'
import Signup from 'components/Views/SignUp/Signup'

// custom style
import useStyles from "assets/jss/components/views/questCategoryStyle";
import DefaultImage from "assets/img/task/default.png";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (
        <div style={{ padding: 30 }} >
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6} >
                            <Skeleton animation="wave" variant="rect" height={40} style={{ margin: '0px 10px 10px 10px' }} />
                        </GridItem>
                    </GridContainer>


                    <Card className={classes.cardStyle} style={{ margin: 10 }}>
                        <CardContent>
                            <Typography variant="h4" component="h2">
                                <Skeleton animation="wave" />
                            </Typography>
                            <Skeleton animation="wave" variant="rect" height={40} />
                        </CardContent>
                    </Card>

                </GridItem>


                <GridItem xs={12} sm={12} md={12} style={{ margin: 10., marginTop: 30 }}>
                    <GridContainer>
                        {['001', '010', '011', '111', '112', '113'].map((v, i) =>
                            <GridItem key={"SKL" + v} xs={12} sm={3} md={2} style={{ marginBottom: 10 }}>
                                <Card className={classes.contentCardStyle} >
                                    <Skeleton variant="rect" height={220} />
                                    <CardContent >
                                        <div style={{ display: 'flex', flexDirection: "column", width: '100%' }}>
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </div>
                                    </CardContent>
                                </Card>
                            </GridItem>
                        )}
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    )
}


const AutoPlaySwipeableViews = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={props.activeStep}
            containerStyle={{ width: sm ? '100%' : '20%', minWidth: 200 }}
            style={{ padding: props.activeStep === 0 ? md ? '0px 59% 0px 0px' : '0px 60px 0px 0px' : md ? '0px 49.5% 0 10%' : '0px 30px' }}
            onChangeIndex={props.stepChange}
            enableMouseEvents
            ignoreNativeScroll={true}
        >
            {props.topics.map((step, index) => (
                <div key={step.questId} style={{ width: '100%' }}>
                    <GridContainer className={classes.webTopicCard}>
                        <GridItem xs={12} sm={12} md={12} className={classes.topicCardGrid} >
                            <Card className={classes.contentCardStyle}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    component="img"
                                    alt={step.questName}
                                    height="140"
                                    image={step.questIcon ? step.questIcon : DefaultImage}
                                    title={step.questName}
                                />
                                <CardContent >
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <Typography variant="subtitle2" component="h2" className={classes.contentCardTitle}>
                                            {step.questName}
                                        </Typography>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                                            <samp className={classes.hyperText} onClick={() => props.onSeeMore(step)}>Details</samp>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', visibility: (!(step.isFree || step.isAssigned)) ? 'visible' : "hidden" }}>
                                                <Typography variant="caption" style={{ paddingRight: 2 }}>
                                                    ₹
                                                </Typography>
                                                <Typography variant="caption" style={{ color: '#000', fontWeight: "bold", paddingRight: 10 }} >
                                                    {Math.floor(step.price) + ''}
                                                </Typography>
                                            </div>
                                        </div>

                                        {step.isFree
                                            ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                <div></div>
                                                {
                                                    step.isOnline === true || step.isOnline === undefined || step.isOnline === null
                                                        ? <Button fullWidth style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(step)}  >Launch</Button>
                                                        : <Button color="info" size="xs" onClick={() => props.onOfflineLaunch(step.formUrl)} >I am interested</Button>
                                                }

                                            </div>
                                            : step.isTrialTaken
                                                ? step.isAssigned
                                                    ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(step)}  >Launch</Button>
                                                    </div>
                                                    : <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <div></div>
                                                        <Button fullWidth style={{ width: '40%' }} label='Buy' color="rose" size="xs" onClick={() => props.onBuy(step)}  >Buy</Button>
                                                    </div>
                                                : step.isAssigned
                                                    ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(step)}  >Launch</Button>
                                                    </div>
                                                    : step.isOnline === true || step.isOnline === undefined || step.isOnline === null
                                                        ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: -15 }}>
                                                            <Button fullWidth style={{ marginRight: 3, marginLeft: -1 }} color="warning" size="xs" onClick={() => props.onSeeMore(step)}  >Try</Button>
                                                            <Button fullWidth style={{ marginLeft: 3 }} color="rose" size="xs" onClick={() => props.onBuy(step)}  >Buy</Button>
                                                        </div>
                                                        : <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                            <Button color="info" size="xs" onClick={() => props.onOfflineLaunch(step.formUrl)} >I am interested</Button>
                                                        </div>
                                        }
                                    </div>
                                </CardContent>
                            </Card>

                            <Typography variant="caption" component="h2" className={classes.freeText} style={{ backgroundColor: step.tagColor }}>
                                {step.tagValue}
                            </Typography>

                        </GridItem>
                    </GridContainer>
                    {/* <TopicCards {...step} /> */}
                </div >
            ))
            }
        </SwipeableViews >
    )
};

const CardConterol = (props) => {
    const theme = useTheme();
    return (
        <div>
            <Button justIcon color="white" size="sm" round onClick={props.onBack} disabled={props.activecard === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft />}
            </Button>

            <Button justIcon color="white" size="sm" round onClick={props.onNext} disabled={props.activecard === props.controlLength - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight />}
            </Button>
        </div>
    )
}


const CategoryCard = (props) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        < React.Fragment >
            {props.topics.length > 0
                && <div className={classes.buttonDiv}>
                    <Typography variant="body1" className={classes.topicsTitle}>
                        {props.task}
                    </Typography>
                    {sm && !md
                        ? null
                        : <CardConterol
                            onNext={handleNext}
                            onBack={handleBack}
                            activecard={activeStep}
                            controlLength={props.topics.length}
                        />
                    }
                </div>}

            <AutoPlaySwipeableViews
                activeStep={activeStep}
                topics={props.topics}
                stepChange={handleStepChange}
                onSeeMore={props.onSeeMore}
                onLaunch={props.onLaunch}
                onOfflineLaunch={props.onOfflineLaunch}
                onBuy={props.onBuy}
            />
        </React.Fragment >

    )
}


const QuestCategory = (props) => {
    const dispatch = useDispatch();
    const QuestCategories = useSelector(state => state.categories.questcategories)
    const userInfo = useSelector(state => state.user.userinfo)
    const cartItems = useSelector(state => state.store.cartitems)
    const classes = useStyles();
    const [searchText, setSearchText] = useState("")
    const [loading, setLoading] = useState(false)
    const [questDialog, setQuestDialog] = React.useState(false);
    const [questData, setQuestData] = React.useState({
        quest: null,
        isTrialQuest: false
    });

    const [loginDialog, setLoginDialog] = React.useState(false);
    const [signupDialog, setSignupDialog] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("danger");



    /*-----------------------handle Quest Dialog Open --------------------*/
    const openQuestDialog = (CurrentQuest) => {
        setQuestData({ ...questData, quest: CurrentQuest });
        setQuestDialog(true);
    };
    /*-----------------------handle Quest Dialog Close --------------------*/
    const closeQuestDialog = () => {
        setQuestDialog(false);
    };

    /*-----------------------handle Login Dialog Open --------------------*/
    const openLoginDialog = (CurrentQuest, isTrialQuest) => {
        setQuestData({ quest: CurrentQuest, isTrialQuest: isTrialQuest });
        setLoginDialog(true)
    };

    /*-----------------------handle Login Dialog Close --------------------*/
    const closeLoginDialog = () => {
        setLoginDialog(false);
    };

    /*-----------------------handle Signup Dialog Open --------------------*/
    const openSignupDialog = () => {
        closeLoginDialog();
        setSignupDialog(true)
    };

    /*-----------------------handle Signup Dialog Close --------------------*/
    const closeSignupDialog = () => {
        setSignupDialog(false);
    };

    /*----------------------- Change Quest Data --------------------*/
    const onChangeQuest = async (CurrentQuest, isTrialQuest = false) => {
        if (userInfo.userId) {
            assignQuest(CurrentQuest, isTrialQuest)
        } else {
            openLoginDialog(CurrentQuest, isTrialQuest)
        }
    }

    /*----------------------- Change Offline Quest --------------------*/
    const onChangeOfflineQuest = (URL) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.openNewUrl("", URL)
        } else if (typeof openNewUrl !== 'undefined') {
            window.openNewUrl("", URL)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('openNewUrl', "", URL);
        } else {
            window.open(URL, "_blank")
        }
    }

    /*----------------------- Assign Quest --------------------*/
    const assignQuest = async (CurrentQuest, isTrialQuest, initUserInfo = false) => {
        try {
            if (!(Object.keys(CurrentQuest).includes("viewedOn") || CurrentQuest.isAssigned)) {
                if (isTrialQuest) {
                    await dispatch(questActions.postAssignTrialQuest(CurrentQuest.questId)).then(() => {
                        setCurrentQuestAndInitContentList(CurrentQuest, initUserInfo)
                    })
                } else {
                    await dispatch(questActions.postAssignQuest(CurrentQuest.questId)).then(() => {
                        setCurrentQuestAndInitContentList(CurrentQuest, initUserInfo)
                    })
                }
            } else {
                setCurrentQuestAndInitContentList(CurrentQuest, initUserInfo)
            }

            //Clevertap 
            if (typeof window.Android !== 'undefined') {
                window.Android.onEventReceived("QuestLaunch", userInfo.userId, true, true)
            } else if (typeof onEventReceived !== 'undefined') {
                window.onEventReceived("QuestLaunch", "{\"userId\":\"" + userInfo.userId + "\"}", true, true)
            } else if (typeof window.flutter_inappwebview !== 'undefined') {
                window.flutter_inappwebview.callHandler('onEventReceived', "QuestLaunch", userInfo.userId, true, true);
            }

            props.history.push('/home')
        } catch (error) {
            setAlertType("danger")
            setAlertMessage(error.message)
        }
    }

    const setCurrentQuestAndInitContentList = async (CurrentQuest, initUserInfo = false) => {
        dispatch(questActions.setCurrentQuest(CurrentQuest))
        await dispatch(questActions.initContentList(CurrentQuest.questId)).then(() => {
            if (initUserInfo)
                dispatch(questActions.initUserInfo())
        })
    }

    useEffect(() => {
        fetchQuestCategory()
    }, [userInfo.userId])


    const fetchQuestCategory = () => {
        // if (userInfo.userId) {
        setLoading(true)
        // Fetch Quest Category and Cart Item 
        dispatch(questActions.fetchQuestCategory()).then(() => {
            setLoading(false)
        })
        // }
    }

    /*-----------------------handel Login --------------------*/
    const handelLogin = async () => {
        closeLoginDialog();
        assignQuest(questData.quest, questData.isTrialQuest);
    }

    /*-----------------------handel Signup --------------------*/
    const handelSignup = async () => {
        closeSignupDialog();
        setLoading(true)
        await assignQuest(questData.quest, questData.isTrialQuest, true);

    }


    //-----------------------Handel Buy Quest---------------------
    const onBuyQuest = async (CurrentQuest) => {
        const cartItem = {
            "cartId": "",
            "userId": userInfo.userId,
            "questId": CurrentQuest.questId,
            "questName": CurrentQuest.questName,
            "qty": 1,
            "price": Number(CurrentQuest.price)
        }

        await dispatch(questActions.addToCart(cartItem));
        props.history.push('/cart');
    }

    // Close Alert
    const onCloseAlert = () => {
        setAlertMessage("")
    }

    return (
        <div className='page-container-0 background-page-container-0'>
            <CssBaseline />
            {/*-----Dialog----- */}
            <Dialog scroll="body" open={questDialog} TransitionComponent={Transition} fullWidth={true} classes={{ paper: classes.paper }} maxWidth="xs" >
                <QuestDetails
                    data={questData.quest}
                    onClose={closeQuestDialog}
                    onLaunch={onChangeQuest}
                    onOfflineLaunch={onChangeOfflineQuest}
                    onBuy={onBuyQuest}
                />
            </Dialog>

            <Dialog scroll="body" open={loginDialog} TransitionComponent={Transition} fullWidth={true} classes={{ paper: classes.paper }} maxWidth="xs" onClose={closeLoginDialog}>
                <SigninComponent
                    onLogin={handelLogin}
                    onSignUp={openSignupDialog}
                    isInitCurrentQuestAndContentList={false}
                />
            </Dialog>

            <Dialog scroll="body" open={signupDialog} TransitionComponent={Transition} fullScreen   >
                <Signup
                    onSignUp={handelSignup}
                    onClose={closeSignupDialog}
                    isLoginAfterSignUp={true}
                />
            </Dialog>

            {/* AlertDialog */}
            <AlertDialog
                maxWidth="xs"
                open={alertMessage ? true : false}
                type={alertType}
                ContentText={alertMessage}
                ContentTextStyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titleStyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={[{ text: 'OK', onPress: () => onCloseAlert() }
                ]}
            />



            {(loading || QuestCategories.lastViewed === "")
                ? <SkeletonCard />
                : <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <div style={{ padding: 30 }} >

                            <GridContainer>
                                <GridItem xs={10} sm={10} md={6} className={classes.searchTextContainer}>
                                    <div className={classes.backButtonContainer}>
                                        <IconButton style={{ display: userInfo.userId ? !userInfo.lastQuestAccessed ? "none" : "visible" : "visible", color: "#fff" }} onClick={() => props.history.push(userInfo.lastQuestAccessed ? '/home' : '/')}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <SearchInput
                                            inputchange={(text) => setSearchText(text)}
                                        />
                                    </div>
                                </GridItem>
                                {cartItems.length > 0 ?
                                    <GridItem xs={2} sm={2} md={6} className={classes.cartIconContainer}>
                                        <IconButton onClick={() => props.history.push('/cart')} style={{ color: "#fff" }}>
                                            <Badge badgeContent={cartItems.length} color="primary">
                                                <ShoppingCartTwoToneIcon />
                                            </Badge>
                                        </IconButton>
                                    </GridItem>
                                    : null
                                }

                            </GridContainer>

                            {QuestCategories.lastViewed.questName && <Card className={classes.cardStyle} style={{ margin: 10, padding: 10 }}>
                                <CardHeader
                                    action={
                                        <Button color="primary" size="sm" onClick={() => onChangeQuest(QuestCategories.lastViewed)} >
                                            Launch
                                        </Button>
                                    }
                                    title={QuestCategories.lastViewed.questName}
                                    titleTypographyProps={{ variant: 'body1', className: classes.contentCardTitle }}
                                    subheader={`Viewed on date: ${QuestCategories.lastViewed.viewedOn}`}
                                    subheaderTypographyProps={{ variant: 'caption' }}
                                />
                            </Card>
                            }

                            {QuestCategories.questCategory.map((data, idex) => (
                                <CategoryCard
                                    key={"CategoryCard" + idex}
                                    task={data.categoryName}
                                    topics={data.questList.filter(quest => quest.questName.trim().toUpperCase().includes(searchText.trim().toUpperCase()))}
                                    onSeeMore={openQuestDialog}
                                    onLaunch={onChangeQuest}
                                    onOfflineLaunch={onChangeOfflineQuest}
                                    onBuy={onBuyQuest}
                                />))}
                        </div>
                    </GridItem>
                </GridContainer>
            }
        </div>
    )
}

export default QuestCategory;