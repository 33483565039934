import {
  dangerColor,
  boxShadow
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
  contentCardStyle: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 10,
    // cursor: 'pointer',
    boxShadow: '0 2px 2px -5px rgba(0, 0, 0, 0.42), 0 1px 10px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  listGridContainer: {
    display: 'flex',
    justifyContent: "center"
  },

  contentCardTitle: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    // height: '3em',
    /* line-height: 16px; */
    /* max-height: 32px; */
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  webTopicCard: {
    height: '100%',
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    margin: 0
  },
  hyperText: {
    fontSize: '0.75rem',
    fontWeight: 800,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    textDecoration: 'underline',
    color: "#0000FF",
    cursor: 'pointer'
  },

  mobileStepper: {
    flexGrow: 1,
    justifyContent: "center !important",
    background: 'transparent !important'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: '100%',
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  swipeableViewsContainer: {
    paddingTop: 20
  }, buttonDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    // textAlign: 'center',
    alignItems: 'center',
    padding: '0 15px',
  },
  topicsTitle: {
    width: '85%',
    fontWeight: 'bold',
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('sm')]: {
      '-webkit-line-clamp': '2',
    },
  },

  freeText: {
    width: 'max-content',
    justifyContent: "center",
    // backgroundColor: '#ff414e',
    paddingRight: 20,
    paddingLeft: 10,
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 10,
    fontSize: 12,
    color: "#fff",
    fontWeight: 'bold',
    textAlign: "center"
  },
  cardMedia: {
    // width:'50% !important',
    objectFit: 'fill !important',
    backgroundColor: ' #D4DFF9',
    height: 'auto'
  },
  topicCardGrid: {
    paddingRight: '2px !important'
  },
  paper: { borderRadius: 10 },
  backButtonContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: 'center',
    margin: '0px 0px 0px 0px',

  },

  backButtonContainer1: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-18%'
    },
  },

  cartIconContainer: {
    position: 'absolute',
    right: 20,
    display: "flex",
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 15px'
    },
  },
  searchTextContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 15px'
    },
  },
  priceTextContainer: {
    // lineHeight: 1,
    display: 'flex',
    alignItems: 'flex-end'
  },
  priceSymbol: {
    paddingRight: 2,
    lineHeight: 'inherit'
  },
  priceText: {
    color: '#000',
    fontWeight: "bold",
    paddingRight: 10,
    lineHeight: 'inherit'
  },
  button: {
    padding: "0.2rem 0.8rem",
    fontSize: "0.875rem",
  },
  boardGradeSearchContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));

export default useStyles;