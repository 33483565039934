import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
    gridItemMissions: {
        padding: 5
    },
    cardMissions: {
        backgroundColor: 'transparent',
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 248,
        marginBottom: "0px",
        boxShadow: 'none',
        margin: '0px 0px 0px 0px'
    },
    imageStyle: {
        width: "auto",
        height: "-webkit-fill-available"
    },
    infoText: {
        textAlign: 'center',
        lineHeight: '1em',
        height: '2em',
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },
    stats:{
        padding:'5px 5px 0px 5px'
    }

}));

export default useStyles;