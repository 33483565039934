import React, { useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

//@material-ui/icons
import PersonIcon from '@material-ui/icons/Person';
//Custom Components

import Button from "components/CustomComponents/CustomButtons/Button.js";

import useStyles from "assets/jss/components/views/friendListStyle"

export default function LeaderBoard(props) {
    const classes = useStyles();
    return (
        <React.Fragment>

            <List dense className={classes.listStyle}>
                {props.loading
                    ? <div>loading....</div>
                    : props.data.length === 0 ?
                        <Alert severity="warning">Leader board list not available!</Alert>
                        : props.data.map((value,idx) => (
                            <ListItem className={classes.listItemStyle} key={"LBORD#"+idx}>
                                <ListItemAvatar className={classes.listItemAvatarStyle}>
                                    <Avatar className={classes.avatar}>
                                        <PersonIcon fontSize="small" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography={true}
                                    primary={<Typography className={classes.heading}>{value.name}</Typography>}
                                    secondary={<Typography className={classes.heading}>{value.score + " " + "Points"}</Typography>}
                                />
                            </ListItem>
                        ))}

            </List>
        </React.Fragment>

    );
}