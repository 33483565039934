import React from 'react'
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from "assets/jss/components/customcomponents/progressStyle";

const useStyles = makeStyles(styles);

const Progress = (props) => {
    const classes = useStyles();
    const {
        open,
        handleClose,
        ...rest
    } = props;
    return (<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <div className="loader">Loading...</div>
        {/* <CircularProgress color="inherit" /> */}
    </Backdrop>)
}

Progress.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default Progress;