import { makeStyles } from "@material-ui/core/styles";

import {
  successColor
} from "assets/jss/material-dashboard-react.js";


const Style = makeStyles((theme) => ({
  paper:{
    margin:-5,
    overflow:'hidden',
    padding: '0px 24px 0px 24px',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }

}));

export default Style;