import React from 'react';
//@material-ui/core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ShowMoreText from 'react-show-more-text';
import Skeleton from '@material-ui/lab/Skeleton';
// custom style
import useStyles from "assets/jss/components/views/NotificationStyle";

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        {['SK0011', 'DK0101', 'PL0111', 'LK1111'].map((v, i) =>
            <Card className={classes.cardStyle} key={v}>
                <CardContent>
                    <Skeleton animation="wave" variant="rect" />
                    <div style={{ height: 5 }}></div>
                    <Skeleton animation="wave" variant="rect" height={30} />
                </CardContent>

            </Card>
        )}
    </React.Fragment>
    )
}



const NotificationCard = (props) => {
    const classes = useStyles();

    if (props.loading) {
        return (<SkeletonCard></SkeletonCard>)
    }



    return (
        <React.Fragment>
            {props.notifications.length ?
                props.notifications.map((data, idex) => (
                    <Card key={idex + "_NoT"} className={classes.cardStyle}>
                        <CardContent>
                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                <Typography variant="caption" color="textSecondary" gutterBottom>
                                    {data.modifiedOn}
                                </Typography>
                                <ShowMoreText
                                    lines={2}
                                    more={<><br></br><span>Show more</span></>}
                                    less={<><br></br><span>Show less</span></>}
                                    className='content-css'
                                    anchorClass='my-anchor-css-class'
                                    expanded={false}
                                //    width={600}
                                >
                                    {data.messageText}
                                </ShowMoreText>
                            </div>
                        </CardContent>
                    </Card>
                ))
                : <Card className={classes.cardStyle}>
                    <CardContent style={{ justifyContent: "center", display: 'flex' }}>
                        <Typography variant="body1" color="textSecondary">
                            {"Notification not available."}
                        </Typography>

                    </CardContent>
                </Card>
            }
        </React.Fragment>
    );
}

export default NotificationCard;