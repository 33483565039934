import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";



//-------------Message Announcements-----------------

export const setFetchLoadingStatus=(status)=>{
    return{
         type:actionTypes.FETCH_QUERY_LOADING,
         status: status,
    }
}


export const setMessageAnnouncements = (messages) => {
    return {
        type: actionTypes.SET_QUERY_DATA,
        inbox: messages,
    }
};

export const fetchMessageAnnouncements = () => {
    return async (dispatch, getState) => {
        dispatch(setFetchLoadingStatus(true))
        await axiosConfig().get('/QuestMessage/GetMessageList').then(response => {
            dispatch(setMessageAnnouncements(response.data))
        }).catch(error => {
            dispatch(setFetchLoadingStatus(false))
            throw new Error(error)
        });
    }
}

// ----------Post Query Message--------------
export const postQueryMessage = (message, attachmentList) => {
    return async (dispatch, getState) => {
        const formData = new FormData();
        formData.append('messageTypeId', 1)
        formData.append('questId', message.questId)
        formData.append('missionId', message.missionId)
        formData.append('operationId', message.operationId)
        formData.append('subject', message.subject)
        formData.append('messageBody', message.messageBody)
        formData.append('parentMessageId', message.parentMessageId)
        formData.append('isActive', true)
        attachmentList.forEach((element, index) => {
            formData.append(`attachmentList[${index}].id`, index + '');
            formData.append(`attachmentList[${index}].fileName`, element.name);
            formData.append(`attachmentList[${index}].fileSize`, element.size.toString());
            formData.append(`attachmentList[${index}].fileType`, element.type);
            formData.append(`attachmentList[${index}].isActive`, 'true');
            formData.append(`attachmentList[${index}].isDeleted`, 'false');
            formData.append(`attachmentList[${index}].isNew`, 'true');
            formData.append(`attachmentList[${index}].index`, index + '');
            formData.append(`attachmentList[${index}].fileUrl`, '');
            formData.append(`attachmentList[${index}].file`, element);
        });


        await axiosConfig().post('/QuestMessage/InsertUpdateMessage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response=>{
            dispatch(fetchMessageAnnouncements())
        }).catch((error) => {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}
