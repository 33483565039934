import {  
    roseColor
  } from "assets/jss/material-dashboard-react.js";



const progressStyles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: roseColor[0],
    },
});

export default progressStyles;