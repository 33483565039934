import React from 'react'

// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

//style
import useStyles from "assets/jss/components/customcomponents/searchInputStyle";

const SearchInput = (props) => {
    const classes = useStyles();
    const {
        open,
        handleClose,
        styles,
        ...rest
    } = props;
    return (
        <div className={classes.search} style={{ ...styles }}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search…"
                onChange={e => props.inputchange(e.target.value)}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    )
}

SearchInput.defaultProps = {
    styles: { color: "#fff", width: '100%' }
}

SearchInput.propTypes = {
    inputchange: PropTypes.func,
    styles:PropTypes.object
};

export default SearchInput;